import React from 'react'
import XLSX from 'xlsx'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, TextField, Tooltip, Divider } from '@material-ui/core'

import CustomModal from 'components/Modal/CustomModal'

import Timeline from 'components/Timeline/Timeline.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardText from 'components/Card/CardText.js'
import CardBody from 'components/Card/CardBody.js'
import { getReq, emptyRequest } from 'utils'
import CardTravel from '@material-ui/icons/CardTravel'

import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'

import Person from '@material-ui/icons/Person'
import Magnify from '@material-ui/icons/Search'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Button from 'components/CustomButtons/Button.js'
import ReactTable from 'components/ReactTable/ReactTable.js'

import { useHttp } from 'hooks/useHttp'
import { getRequest } from 'constants/request'
import Search from '@material-ui/icons/Search'

const useStyles = makeStyles(styles)
const emptyReq = emptyRequest()

// Historial de subscripciones compradas por usuarios
//  query de usuarios que han comprado al menos una Subscripcion

// Historial de pagos de comisión realizados a usuarios subscriptores
//  Al ver el detalle del usuario: expandir todas las compras (MERCADOPAGO) de ese usuario
//  utilizar algun tipo de timeline si es posible

// Pago individual a subscriptor, más como confirmar y enviar pago porque se pre-calcula todo el proceso
//  Accion dentro del detalle: Agregar al usuario a "cola de pago", es decir, se apruba el pago de
//  Ablyz hacia el usuario suscriptor

// Al ver el detalle de la persona por pagar revisar documentos: INE, licencia y pasaporte
//  Tener un apartado para mostrar los documentos adjuntos acerca de la información
//  privada del usuario

const AccountingPage = () => {
  const classes = useStyles()
  const [data, setData] = React.useState([])
  const [detailOpen, setDetailOpen] = React.useState(null)
  const [modelsRequest, setModelsRequest] = React.useState(emptyReq)
  const [models, modelsLoading, modelsError] = useHttp(modelsRequest)

  React.useEffect(() => {
    const usersReq = getRequest('users')
    setModelsRequest(usersReq)
  }, [])

  React.useEffect(() => {
    if (!modelsLoading && !modelsError && models) {
      if (!models.length) return
      // console.log(models.flat((e) => e.referrals));
      const dat = models.map((m) => dataToRow(m, m.id))
      setData([...dat])
    }
  }, [models, modelsLoading, modelsError])

  const dataToRow = React.useCallback(
    (prop, key) => {
      const { displayName, subscriptions, invitees } = prop
      let status = 'INACTIVA'
      const tmp = new Array(...subscriptions)
      if (
        tmp.some((s) => {
          const status = s.status
          return status === 1
        })
      ) {
        status = 'ACTIVA'
      }
      return {
        id: key,
        displayName,
        status: <strong>{status}</strong>,
        invitees: (
          <span>
            <strong>{invitees ? parseInt(invitees).toString() : '0'}</strong>{' '}
            invitados
          </span>
        ),
        actions: (
          <div className="actions-right">
            <Tooltip title="Ver detalle">
              <Button
                justIcon
                round
                simple
                onClick={() => setDetailOpen(prop)}
                color="info"
                className="like"
              >
                <Magnify />
              </Button>
            </Tooltip>
            <Tooltip title="Ver perfil">
              <Button
                justIcon
                round
                simple
                onClick={() => {}}
                color="success"
                className="edit"
              >
                <Person />
              </Button>
            </Tooltip>
          </div>
        )
      }
    },
    [data, models]
  )

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={10}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h3 className={classes.cardTitle}>
                <strong>Administración e Historial de Contabilidad</strong>
              </h3>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <Typography style={{ fontWeight: 'bold', marginBottom: 20 }}>
                  Rango de Fechas
                </Typography>
              </GridItem>
              <GridItem xs={6}>
                <TextField
                  id="date"
                  label="Fecha de Inicio"
                  type="date"
                  defaultValue="2017-05-24"
                  style={{ width: '100%', marginBottom: 20 }}
                  variant="outlined"
                  size="small"
                />
              </GridItem>
              <GridItem xs={6}>
                <TextField
                  id="date"
                  label="Fecha Final"
                  type="date"
                  defaultValue="2017-05-24"
                  style={{ width: '100%', marginBottom: 20 }}
                  variant="outlined"
                  size="small"
                />
              </GridItem>
              <GridItem xs={12} align="end">
                <Button color="primary" icon={<Search />}>
                  Buscar por rango de fechas
                </Button>
              </GridItem>
            </GridContainer>
            <Divider
              style={{ marginBottom: 16, marginTop: 16, width: '100%' }}
            />

            <ReactTable
              loading={modelsLoading}
              columns={[
                {
                  Header: 'Nombre(s) y Apellido(s)',
                  accessor: 'displayName'
                },
                {
                  Header: 'Estatus Subscripción',
                  accessor: 'status'
                },
                {
                  Header: 'Número de invitados',
                  accessor: 'invitees'
                },
                {
                  Header: 'Acciones',
                  accessor: 'actions'
                }
              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
      <CustomModal
        open={Boolean(detailOpen)}
        onCancel={() => setDetailOpen(null)}
        onAccept={() => setDetailOpen(null)}
        title={detailOpen?.displayName}
      >
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Typography itemType="h2" style={{ marginBottom: 24 }}>
              Historial de compra y renovación de subscripciones
            </Typography>
          </GridItem>

          <GridItem xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <strong>Ver información de Facturación:</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <GridContainer justify="center">
                  <GridItem xs={4}>Información Fiscal General:</GridItem>
                  <GridItem xs={8}>EMPRESA SA DE CV</GridItem>
                  <Divider style={{ width: '100%', marginBottom: 12 }} />
                  <GridItem xs={4}>Dirección de Facturación</GridItem>
                  <GridItem xs={8}>
                    Calle Alamos #501, Col. Satelite 82300 Sonora, México
                  </GridItem>
                  <Divider style={{ width: '100%', marginBottom: 12 }} />
                  <GridItem xs={4}>Documentación Oficial:</GridItem>
                  <GridItem xs={8}>item 3</GridItem>
                </GridContainer>
              </AccordionDetails>
            </Accordion>
          </GridItem>
        </GridContainer>
        <Timeline
          simple
          stories={[
            {
              // First story
              inverted: false,
              badgeColor: 'info',
              badgeIcon: CardTravel,
              title: 'Compra inicial - MXN $1,010.00',
              titleColor: 'info',
              body: (
                <p>
                  <strong>Subscripción Mensual</strong> - Asesorías Financieras
                </p>
              ),
              footerTitle: '01/Septiembre/2021'
            },
            {
              // First story
              inverted: true,
              badgeColor: 'info',
              badgeIcon: CardTravel,
              title: 'Renovación - MXN $200.00',
              titleColor: 'info',
              body: (
                <p>
                  <strong>Subscripción Mensual</strong>- Asesorías Financieras
                </p>
              ),
              footerTitle: '01/Octubre/2021'
            },
            {
              // First story
              inverted: true,
              badgeColor: 'info',
              badgeIcon: CardTravel,
              title: 'Renovación - MXN $120.00',
              titleColor: 'info',
              body: (
                <p>
                  <strong>Subscripción Mensual</strong>- Asesorías Financieras
                </p>
              ),
              footerTitle: '01/Noviembre/2021'
            }
          ]}
        />
      </CustomModal>
    </GridContainer>
  )
}

export default AccountingPage
