import React from 'react'

// core components
import Wizard from 'components/Wizard/Wizard.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import Step1 from './WizardSteps/Step1.js'
import Step2 from './WizardSteps/Step2.js'
import Step3 from './WizardSteps/Step3.js'
import { useAuth } from 'hooks/useAuth.js'
import { useHttp } from 'hooks/useHttp.js'
import { emptyRequest, getReq } from 'utils/index.js'
import HttpService from 'utils/httpService.js'
import { Button, Typography } from '@material-ui/core'
import subscriptionStatus from 'utils/subscriptionStatus.js'

export const Step1Func = () => {
  return <Step1 />
}

const emptyReq = emptyRequest()

export default function WizardView () {
  const { user, userLoading } = useAuth()
  let firstName = ''
  if (user) firstName = user.firstName
  let lastName = ''
  if (user) lastName = user.lastName
  let email = ''
  if (user) email = user.email
  let photoUrl = ''
  if (user) photoUrl = user.photoUrl
  const [selectedCategory, setSelectedCategory] = React.useState(null)
  const [selectedSubscription, setSelectedSubscription] = React.useState(null)
  const [categoriesRequest, setCategoriesRequest] = React.useState(emptyReq)
  const [subscriptionsRequest, setSubscriptionsRequest] = React.useState(
    emptyReq
  )
  const [categories, categoriesLoading] = useHttp(categoriesRequest)
  const [subscriptions, subscriptionsLoading] = useHttp(subscriptionsRequest)
  const [finalLoading, setFinalLoading] = React.useState(false)
  const [pendingPayment, setPendingPayment] = React.useState(null)
  const [linkMp, setLinkMp] = React.useState('')
  // const []

  React.useEffect(() => {
    const catReq = getReq('categories')
    setCategoriesRequest(catReq)
    const subsReq = getReq('subscription')
    setSubscriptionsRequest(subsReq)
  }, [])

  React.useEffect(() => {
    if (user && !userLoading) {
      const pending = user.subscriptions.find(
        (e) => e.status === subscriptionStatus.PENDING
      )
      if (pending) {
        setPendingPayment({ ...pending })
        setLinkMp(pending.paymentUrl)
      }
    }
  }, [user, userLoading])

  const onCategorySelected = React.useCallback(
    (selected) => {
      const cat = categories.find((cat) => cat.id == selected)
      setSelectedCategory({ ...cat })
    },
    [setSelectedCategory, categories]
  )

  const onSubscriptionSelected = React.useCallback(
    (selected) => {
      const sub = subscriptions.find((sub) => sub.id == selected)
      setSelectedSubscription(sub ? { ...sub } : null)
    },
    [setSelectedSubscription, subscriptions]
  )

  const MemoStep1 = React.useMemo(() => {
    return (props) => (
      <Step1 {...props} values={{ firstName, lastName, email, photoUrl }} />
    )
  }, [firstName, lastName, email, photoUrl])

  const MemoStep2 = React.useMemo(() => {
    return (props) => (
      <Step2
        {...props}
        categories={categories}
        categoriesLoading={categoriesLoading}
        onCategorySelected={onCategorySelected}
        subscriptions={subscriptions}
        subscriptionsLoading={subscriptionsLoading}
        onSubscriptionsSelected={onSubscriptionSelected}
      />
    )
  }, [
    categories,
    categoriesLoading,
    onCategorySelected,
    subscriptions,
    subscriptionsLoading,
    onSubscriptionSelected
  ])

  const MemoStep3 = React.useMemo(() => {
    return (props) => (
      <Step3
        {...props}
        category={selectedCategory}
        subscription={selectedSubscription}
      />
    )
  }, [selectedCategory, selectedSubscription])

  const MemoizedSteps = React.useMemo(() => {
    return [
      {
        stepName: 'Información de contacto',
        stepComponent: MemoStep1,
        stepId: 'about'
      },
      {
        stepName: 'Elegir subscripción',
        stepComponent: MemoStep2,
        stepId: 'account'
      },
      {
        stepName: 'Finalizar compra',
        stepComponent: MemoStep3,
        stepId: 'address'
      }
    ]
  }, [MemoStep1, MemoStep2, MemoStep3])

  const onFinishClicked = React.useCallback(
    async (wizardState = {}) => {
      try {
        if (finalLoading) return
        setFinalLoading(true)
        if (wizardState.account) {
          delete wizardState.account
        }
        if (wizardState.address) {
          delete wizardState.address
        }
        wizardState.subscription = selectedSubscription
        wizardState.category = selectedCategory
        await HttpService.post('subscription/subscribe', {
          subscriptionId: wizardState.subscription.id,
          categoryId: wizardState.category.id
        })
        const paymentResponse = await HttpService.post(
          'subscription/payment-url?subscriptionId=' +
            wizardState.subscription.id
        )
        setLinkMp(paymentResponse.paymentUrl)
      } catch (error) {
        console.error(error)
      } finally {
        setFinalLoading(false)
      }
    },
    [selectedSubscription, selectedCategory, finalLoading]
  )

  const onContinueClicked = async () => {
    window.open(linkMp)
  }

  const onUpdateStatusClicked = async () => {
    await HttpService.put('subscription/update-payment-status')
  }

  if (!user || userLoading) return null

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={8}>
        {linkMp !== '' ? (
          <>
            {pendingPayment && (
              <Typography
                style={{ marginTop: 35, marginBottom: 35, textAlign: 'center' }}
              >
                Tienes un pago pendiente.
              </Typography>
            )}
            <Button
              right
              onClick={onContinueClicked}
              style={{
                backgroundColor: '#65c6f0',
                color: 'white',
                height: 40,
                padding: 20,
                fontSize: 22
              }}
            >
              Continuar en Mercado Pago
            </Button>
            {/* <Button
              right
              onClick={onUpdateStatusClicked}
              style={{
                backgroundColor: "#65c6f0",
                color: "white",
                height: 40,
                padding: 20,
                fontSize: 22,
              }}
            >
              Actualiza el estatus de la compra
            </Button> */}
          </>
        ) : (
          <Wizard
            nextButtonText="Siguiente"
            previousButtonText="Volver"
            validate
            steps={MemoizedSteps}
            title="COMPRA TU SUBSCRIPCIÓN ABLYZ"
            subtitle="Forma parte de la red más grande de contacto de servicios."
            finishButtonClick={onFinishClicked}
            finishButtonText={'Finalizar compra'}
          />
        )}
      </GridItem>
    </GridContainer>
  )
}
