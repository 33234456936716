import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: "",
      subscription: false,
    };
  }

  componentDidMount() {
    // const { firstName, lastName, email } = this.props.values;
    this.setState({
      subscription: true,
    });
  }
  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    event.preventDefault();
    const ele = { [event.target.name]: event.target.value };
    this.setState(ele);
    this.props.onCategorySelected(event.target.value);
  };
  handleChange = (name, sub = null) => (event) => {
    this.setState({ [name]: event.target.checked });
    if (name === "subscription" && sub) {
      this.props.onSubscriptionsSelected(event.target.checked ? sub.id : null);
    }
  };
  isValidated() {
    return this.state.subscription && this.state.categoryId;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4 className={classes.infoText}>Elige tu tipo de suscripción</h4>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <GridContainer justify="center">
              {/* <GridItem xs={12} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={this.handleChange("desgin")}
                    checkedIcon={
                      <i
                        className={
                          "fas fa-pencil-alt " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    icon={
                      <i
                        className={
                          "fas fa-pencil-alt " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <h6>Design</h6>
                </div>
              </GridItem>
             */}
              {this.props.subscriptions &&
                this.props.subscriptions.map((sub, i) => (
                  <GridItem key={i} xs={12} sm={4}>
                    <div className={classes.choiche}>
                      <Checkbox
                        name="subscription"
                        onClick={this.handleChange("subscription", sub)}
                        checkedIcon={
                          <i
                            className={
                              "fas fa-credit-card " + classes.iconCheckboxIcon
                            }
                          />
                        }
                        icon={
                          <i
                            className={
                              "fas fa-credit-card " + classes.iconCheckboxIcon
                            }
                          />
                        }
                        classes={{
                          checked: classes.iconCheckboxChecked,
                          root: classes.iconCheckbox,
                        }}
                      />
                      <h3>${parseFloat(sub.price).toFixed(2)} MXN</h3>
                      <h5>{sub.name}</h5>
                    </div>
                  </GridItem>
                ))}

              {/* <GridItem xs={12} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={this.handleChange("develop")}
                    checkedIcon={
                      <i
                        className={"fas fa-laptop " + classes.iconCheckboxIcon}
                      />
                    }
                    icon={
                      <i
                        className={"fas fa-laptop " + classes.iconCheckboxIcon}
                      />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <h6>Develop</h6>
                </div>
                
              </GridItem> */}
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  CATEGORÍA POR SUBSCRIBIRSE
                </InputLabel>
                <Select
                  labelId="simple-select"
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  onChange={this.handleSimple}
                  inputProps={{
                    name: "categoryId",
                    id: "simple-select",
                  }}
                  value={this.state.categoryId}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value={""}
                  >
                    Elegir categoría
                  </MenuItem>
                  {!this.props.categoriesLoading &&
                    this.props.categories &&
                    this.props.categories.map((category, index) => (
                      <MenuItem
                        key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={category.id}
                      >
                        {category.name}
                      </MenuItem>
                    ))}
                  {/* <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="2"
                  >
                    Paris
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="3"
                  >
                    Bucharest
                  </MenuItem> */}
                </Select>
              </FormControl>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
