/*!

=========================================================
* Material Kit PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
// @material-ui/icons
import Close from '@material-ui/icons/Close'
// core components
import Button from 'components/CustomButtons/Button.js'

import style from 'assets/jss/material-dashboard-pro-react/modalStyle.js'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const useStyles = makeStyles(style)

export default function CustomModal ({
  children,
  title = 'Sin titulo',
  open = false,
  onCancel = () => {},
  onAccept = () => {},
  cancelText = 'Cancelar',
  okText = 'Aceptar'
}) {
  const classes = useStyles()
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={open}
      TransitionComponent={Transition}
      fullScreen
      keepMounted
      onClose={onCancel}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          simple
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          onClick={onCancel}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h2 className={classes.modalTitle}>{title}</h2>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        {children}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={onCancel} color="secondary">
          {cancelText}
        </Button>
        <Button color="primary" onClick={onAccept}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
