import Calendar from 'views/Calendar/Calendar.js'
import Dashboard from 'views/Dashboard/Dashboard.js'
import FullScreenMap from 'views/Maps/FullScreenMap.js'
import Wizard from 'views/Forms/Wizard.js'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DateRange from '@material-ui/icons/DateRange'
import AccountIcon from '@material-ui/icons/AccountCircle'
import PersonIcon from '@material-ui/icons/Person'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
import CategoryIcon from '@material-ui/icons/Category'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SettingsIcon from '@material-ui/icons/Settings'
import MapIcon from '@material-ui/icons/Map'
import StoreIcon from '@material-ui/icons/Store'
import ListAltIcon from '@material-ui/icons/ListAlt'
import AttachMoney from '@material-ui/icons/AttachMoney'

// public
import LockScreenPage from 'views/Pages/LockScreenPage.js'
import LoginPage from 'views/Pages/LoginPage.js'
import RegisterPage from 'views/Pages/RegisterPage.js'
import PricingPage from 'views/Pages/PricingPage.js'
// private
import Referrals from 'views/Pages/Referrals.js'
import UserProfile from 'views/Pages/UserProfile.js'
import Services from 'views/Pages/Services'
import ExploreServices from 'views/Pages/ExploreServices'
import ServicesDetail from 'views/Pages/ServicesDetail'
import Categories from 'views/Pages/Categories'
import CategoriesDetail from 'views/Pages/CategoriesDetail'

// auth
import ErrorPage from 'views/Pages/ErrorPage.js'
import Subscriptions from 'views/Pages/Subscriptions'
import ActiveRequests from 'views/Pages/ActiveRequests'
import RequestsHistory from 'views/Pages/RequestsHistory'
import NotificationSender from 'views/Pages/NotificationSender'
import PromotionsView from 'views/Pages/Promotions'
import PromotionDetailView from 'views/Pages/PromotionDetail'
import Schedule from '@material-ui/icons/Schedule'
import AccountingView from 'views/Pages/AccountingPage'

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Inicio',
    icon: DashboardIcon,
    component: Dashboard,
    layout: '/admin'
  },
  {
    collapse: true,
    name: 'Mi Cuenta',
    icon: AccountIcon,
    state: 'accountCollapse',
    roles: ['regular', 'subscriber', 'admin'],
    views: [
      {
        path: '/perfil',
        name: 'Mi Perfil',
        icon: PersonIcon,
        component: UserProfile,
        roles: ['regular', 'subscriber', 'admin'],
        layout: '/admin'
      },
      {
        path: '/mis-referencias',
        name: 'Mis Referencias',
        icon: FolderSharedIcon,
        component: Referrals,
        roles: ['subscriber', 'admin'],
        layout: '/admin'
      }
    ]
  },
  {
    collapse: true,
    name: 'Subscripciones',
    icon: AccountIcon,
    state: 'subscriptionsCollapse',
    roles: ['regular', 'subscriber', 'admin'],
    views: [
      {
        path: '/mis-subscripciones',
        name: 'Mis subscripciones',
        icon: CardMembershipIcon,
        component: Subscriptions,
        roles: ['subscriber', 'admin'],
        layout: '/admin'
      },
      {
        path: '/nueva-subscripcion',
        name: 'Nueva subscripción',
        icon: CardMembershipIcon,
        component: Wizard,
        roles: ['regular'],
        layout: '/admin'
      }
    ]
  },
  // {
  //   collapse: true,
  //   name: "Servicios",
  //   icon: AccountIcon,
  //   state: "servicesCollapse",
  //   roles: ["subscriber", "admin"],
  //   views: [
  //     {
  //       path: "/solicitudes-activas",
  //       name: "Solicitudes Activas",
  //       icon: CardMembershipIcon,
  //       component: ActiveRequests,
  //       roles: ["subscriber", "admin"],
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/solicitudes-historial",
  //       name: "Historial de solicitudes",
  //       icon: CardMembershipIcon,
  //       component: RequestsHistory,
  //       roles: ["subscriber", "admin"],
  //       layout: "/admin",
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: 'Servicios',
    icon: StoreIcon,
    state: 'servicesCollapse',
    roles: ['regular', 'subscriber', 'admin'],
    views: [
      {
        path: '/servicios',
        name: 'Explorar',
        icon: ListAltIcon,
        component: ExploreServices,
        roles: ['regular', 'subscriber'],
        layout: '/admin'
      },

      {
        path: '/mis-servicios',
        name: 'Mis servicios',
        icon: ListAltIcon,
        component: Services,
        roles: ['subscriber', 'admin'],
        layout: '/admin'
      },
      {
        path: '/servicio',
        name: 'Detalle de servicio',
        component: ServicesDetail,
        layout: '/admin',
        roles: ['subscriber', 'admin'],
        redirect: true
      }
      // {
      //   path: "/mapa",
      //   name: "Mapa de activos",
      //   icon: MapIcon,
      //   component: FullScreenMap,
      //   roles: ["subscriber", "admin"],
      //   layout: "/admin",
      // },
      // {
      //   path: "/calendario",
      //   name: "Agenda de pendientes",
      //   icon: DateRange,
      //   component: Calendar,
      //   roles: ["subscriber", "admin"],
      //   layout: "/admin",
      // },
    ]
  },
  {
    collapse: true,
    name: 'Administración',
    icon: SettingsIcon,
    state: 'adminCollapse',
    roles: ['admin'],
    views: [
      {
        path: '/categorias',
        name: 'Categorías',
        icon: CategoryIcon,
        component: Categories,
        roles: ['admin'],
        layout: '/admin'
      },
      {
        path: '/categoria',
        name: 'Detalle categoría',
        component: CategoriesDetail,
        roles: ['admin'],
        layout: '/admin',
        redirect: true
      },
      {
        path: '/notificaciones',
        name: 'Notificaciones',
        icon: NotificationsIcon,
        component: NotificationSender,
        roles: ['admin'],
        layout: '/admin'
      },
      {
        path: '/contabilidad',
        name: 'Contabilidad',
        icon: AttachMoney,
        component: AccountingView,
        roles: ['admin'],
        layout: '/admin'
      },
      {
        path: '/promociones',
        name: 'Banners Promocionales',
        icon: Schedule,
        component: PromotionsView,
        roles: ['admin'],
        layout: '/admin'
      },
      {
        path: '/promocion',
        name: 'Banner Promocional',
        icon: Schedule,
        component: PromotionDetailView,
        roles: ['admin'],
        layout: '/admin',
        redirect: true
      }
    ]
  },
  {
    path: '/iniciar-sesion',
    name: 'Login Page',
    mini: 'L',
    component: LoginPage,
    layout: '/publico',
    redirect: true
  },
  {
    path: '/registro',
    name: 'Register Page',
    mini: 'R',
    component: RegisterPage,
    layout: '/publico',
    redirect: true
  },
  {
    path: '/subscripcion',
    name: 'Lock Screen Page',
    mini: 'LS',
    component: PricingPage,
    layout: '/publico',
    redirect: true
  },
  {
    path: '/error-page',
    name: 'Error Page',
    mini: 'E',
    component: ErrorPage,
    layout: '/admin',
    redirect: true
  },
  {
    path: '/error',
    name: 'Error Page',
    mini: 'E',
    component: ErrorPage,
    layout: '/publico',
    redirect: true
  }
]
export default dashRoutes
