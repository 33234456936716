import React from 'react'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import AuthLayout from 'layouts/Auth.js'

const hist = createBrowserHistory()

const PublicRouter = () => {
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/publico" component={AuthLayout} />
        <Redirect exact from="*" to="/publico/iniciar-sesion" />
      </Switch>
    </Router>
  )
}

export default PublicRouter
