import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import { v4 as uuidv4 } from 'uuid'

import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'

import { useQuery } from 'hooks/useQuery'
import { emptyRequest, getReq } from 'utils'
import { useModel } from 'hooks/useModel'

import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardText from 'components/Card/CardText.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardBody from 'components/Card/CardBody.js'
import ImageUpload from 'components/CustomUpload/ImageUpload.js'
import { useModels } from 'hooks/useModels'
import Typography from '@material-ui/core/Typography'

import { storage } from '../../firebase'

const useStyles = makeStyles(styles)
const extraParams = { primary: true }

const environment = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET
  } else if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_PRODUCTION_FIREBASE_STORAGE_BUCKET
  }
}

const ServicesDetail = ({ history }) => {
  const classes = useStyles()
  const query = useQuery()
  const {
    model: service,
    modelLoading: serviceLoading,
    modelError: serviceError,
    updateModel: updateService
  } = useModel({
    name: 'services',
    id: query.get('id')
  })
  const [primaryCategories, primaryCategoriesLoading] = useModels({
    name: 'categories',
    extraParams
  })
  const [selectedCategory, setSelectedCategory] = React.useState(undefined)
  // Nombre servicio
  const [name, setName] = React.useState('')
  const [nameValidationStatus, setNameValidationStatus] = React.useState('')
  // Descripcion servicio
  const [description, setDescription] = React.useState('')
  const [
    descriptionValidationStatus,
    setDescriptionValidationStatus
  ] = React.useState('')
  // Imágen primaria
  const [photoFile, setPhotoFile] = React.useState(null)
  const [photoUrl, setPhotoUrl] = React.useState(null)
  const [
    photoUrlValidationStatus,
    setPhotoUrlValidationStatus
  ] = React.useState('')

  React.useEffect(() => {
    if (service && !serviceLoading && !serviceError) {
      setName(service.name)
      setDescription(service.description)
      setPhotoUrl(service.photoUrl)
    }
  }, [service, serviceLoading, serviceError])

  const _isFormValid = () => {
    let valid = true
    if (name === '') {
      valid = false
      setNameValidationStatus('error')
    }
    if (description === '') {
      valid = false
      setDescriptionValidationStatus('error')
    }
    if (!photoFile) {
      valid = false
      setPhotoUrlValidationStatus('error')
    }

    return valid
  }

  const saveClicked = async () => {
    if (!_isFormValid()) {
      return
    }
    try {
      const code = uuidv4()
      const storageRef = storage.child('services')
      const fileRef = storageRef.child(code)
      await fileRef.put(photoFile)
      const fileUrl = await fileRef.getDownloadURL()
      updateService({
        name,
        description
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onFileChanged = (file) => {
    setPhotoFile(file)
    if (file) {
      setPhotoUrlValidationStatus('success')
    }
  }

  const onCategoryChanged = (e) => {
    // console.log(e.target.value);
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={8} sm={8} md={8}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h3 className={classes.cardTitle}>
                {query.get('id')
                  ? (
                  <>
                    <strong>Editando</strong> servicio
                  </>
                    )
                  : (
                  <>
                    Agregar <strong>nuevo</strong> servicio
                  </>
                    )}
              </h3>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>
              <CustomInput
                success={nameValidationStatus === 'success'}
                error={nameValidationStatus === 'error'}
                labelText="Nombre de servicio*"
                id="categoryName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: name,
                  onChange: (event) => {
                    if (event.target.value !== '') {
                      setNameValidationStatus('success')
                    } else {
                      setNameValidationStatus('error')
                    }
                    setName(event.target.value)
                  }
                }}
              />
              <CustomInput
                success={descriptionValidationStatus === 'success'}
                error={descriptionValidationStatus === 'error'}
                labelText="Descripción de servicio*"
                id="categoryDescription"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: description,
                  onChange: (event) => {
                    if (event.target.value !== '') {
                      setDescriptionValidationStatus('success')
                    } else {
                      setDescriptionValidationStatus('error')
                    }
                    setDescription(event.target.value)
                  }
                }}
              />

              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  CATEGORÍA POR SUBSCRIBIRSE
                </InputLabel>
                <Select
                  labelId="simple-select"
                  fullWidth
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  onChange={onCategoryChanged}
                  inputProps={{
                    name: 'simpleSelect',
                    id: 'simple-select'
                  }}
                  value={selectedCategory}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                    value={''}
                  >
                    Elegir categoría
                  </MenuItem>
                  {primaryCategories.map((category, index) => (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={category.id}
                    >
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <GridContainer justify="center">
                <GridItem xs={12} sm={6}>
                  <legend>
                    <strong>Imágen principal</strong>
                  </legend>
                  {photoUrlValidationStatus === 'error' && (
                    <Typography style={{ color: 'red' }}>
                      *Es necesario subir una fotografía
                    </Typography>
                  )}
                  <ImageUpload
                    onFileChanged={onFileChanged}
                    addButtonProps={{
                      color: 'rose',
                      round: true
                    }}
                    changeButtonProps={{
                      color: 'rose',
                      round: true
                    }}
                    removeButtonProps={{
                      color: 'danger',
                      round: true
                    }}
                  />
                </GridItem>
              </GridContainer>

              <Button
                color="success"
                onClick={saveClicked}
                className={classes.registerButton}
              >
                Guardar servicio
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default ServicesDetail
