import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Assignment from "@material-ui/icons/Assignment";
import EditOutlined from "@material-ui/icons/EditOutlined";
import Info from "@material-ui/icons/Info";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import AddIcon from "@material-ui/icons/Add";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { useHttp } from "hooks/useHttp";
import { getReq, emptyRequest } from "utils";

import XLSX from "xlsx";
import { Tooltip } from "@material-ui/core";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const emptyReq = emptyRequest();

export default function Promotions({ history }) {
  const [data, setData] = React.useState([]);
  const [excelLoading, setExcelLoading] = React.useState(false);
  const [modelsRequest, setModelsRequest] = React.useState(emptyReq);
  const [models, modelsLoading, modelsError] = useHttp(modelsRequest);
  const classes = useStyles();

  const dataToRow = React.useCallback(
    (prop, key) => {
      return {
        id: key,
        detalle: (
          <span>
            <strong>{prop.title} - </strong>
            {prop.description}
          </span>
        ),
        subscriber: prop.description,
        startingDate: prop.startingDate,
        endingDate: prop.endingDate,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Tooltip title="Ver información">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  let obj = data.find((o) => o.id === key);
                }}
                color="info"
                className="info"
              >
                <Info />
              </Button>
            </Tooltip>
            {/* use this button to add a edit kind of action */}
            <Tooltip title="Editar">
              <Button
                justIcon
                round
                simple
                onClick={() => history.push("/admin/promocion?id=" + key)}
                color="warning"
                className="edit"
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Eliminar categoría">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  let obj = data.find((o) => o.id === key);
                }}
                color="danger"
                className="delete"
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
            {/* use this button to remove the data row */}
            {/* <Button
            justIcon
            round
            simple
            onClick={() => {
              var newData = data;
              newData.find((o, i) => {
                if (o.id === key) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  newData.splice(i, 1);
                  return true;
                }
                return false;
              });
              setData([...newData]);
            }}
            color="danger"
            className="remove"
          >
            <Close />
          </Button>{" "} */}
          </div>
        ),
      };
    },
    [data, models]
  );

  React.useEffect(() => {
    const loadModelsReq = getReq("featuredSubscribers");
    setModelsRequest(loadModelsReq);
  }, []);

  React.useEffect(() => {
    if (!modelsLoading && !modelsError && models) {
      if (!models.length) return;
      // console.log(models.flat((e) => e.referrals));
      const mapped = models.map((m) => dataToRow(m, m.id));
      console.log(mapped);
      setData(mapped);
    }
  }, [models, modelsLoading, modelsError]);

  function generateExcel(entries) {
    setExcelLoading(true);
    const filename = "Reporte_Promociones.xlsx";
    const ws_name = "Visitas";
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {
      A2: { v: "Nivel", t: "s" },
      B2: { v: "Nombre completo", t: "s" },
      C2: { v: "Correo electrónico", t: "s" },
      D2: { v: "Fecha de corte", t: "s" },
      // E2: { v: "Código postal", t: "s" },
      // F2: { v: "Celular", t: "s" },
      // G2: { v: "Distrito Federal", t: "s" },
      // H2: { v: "Distrito Local", t: "s" },
      // I2: { v: "Sección", t: "s" },
      // J2: { v: "Nombre de promotor", t: "s" },
      // K2: { v: "Correo de promotor", t: "s" },
      // L2: { v: "Grupo", t: "s" },
      // M2: { v: "Fecha de visita", t: "s" },
    };
    let renInicial = 3;
    for (let i in entries) {
      const {
        firstName,
        lastName,
        email,
        createdOn,
        // codigoPostal,
        // celular,
        // nombreDistritoFederal,
        // nombreDistritoLocal,
        // nombreSeccion,
        // nombrePromotor,
        // correoPromotor,
        // grupo,
        // fechaVisita,
      } = entries[i];
      // let fechaVisitaFormatted = moment(fechaVisita)
      //   .tz(tz)
      //   .format("DD/MM/YYYY");
      ws["A" + renInicial] = {
        v: firstName,
        t: "s",
      };
      ws["B" + renInicial] = { v: lastName, t: "s" };
      ws["C" + renInicial] = { v: email, t: "s" };
      ws["D" + renInicial] = { v: createdOn.toString(), t: "s" };
      // ws["E" + renInicial] = { v: codigoPostal, t: "s" };
      // ws["F" + renInicial] = { v: celular, t: "s" };
      // ws["G" + renInicial] = { v: nombreDistritoFederal, t: "s" };
      // ws["H" + renInicial] = { v: nombreDistritoLocal, t: "s" };
      // ws["I" + renInicial] = { v: nombreSeccion, t: "s" };
      // ws["J" + renInicial] = { v: nombrePromotor, t: "s" };
      // ws["K" + renInicial] = { v: correoPromotor, t: "s" };
      // ws["L" + renInicial] = { v: grupo, t: "s" };
      // ws["M" + renInicial] = { v: fechaVisitaFormatted, t: "s" };
      renInicial++;
    }
    var wscols = [
      { wch: 30 },
      { wch: 40 },
      { wch: 35 },
      { wch: 35 },
      // { wch: 10 },
      // { wch: 15 },
      // { wch: 25 },
      // { wch: 25 },
      // { wch: 15 },
      // { wch: 30 },
      // { wch: 30 },
      // { wch: 30 },
      // { wch: 15 },
    ];
    ws["!cols"] = wscols;
    ws["!ref"] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    setExcelLoading(false);
  }

  const downloadReportClicked = async () => {
    const flatten = (data) => {
      return data.reduce((r, { referrals, ...rest }) => {
        r.push(rest);
        if (referrals) r.push(...flatten(referrals));
        return r;
      }, []);
    };

    const flattened = flatten(models);
    // console.log(flattened);
    generateExcel(flattened);
  };

  if (modelsLoading) return null;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify="flex-end">
          <Button
            round
            color="success"
            disabled={excelLoading}
            onClick={() => history.push("/admin/promocion")}
          >
            Agregar nueva promoción
            <AddIcon style={{ marginLeft: 10 }} />
          </Button>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h3 className={classes.cardIconTitle}>
              Administración y control de banners Promocionales
            </h3>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "Detalle",
                  accessor: "detalle",
                  disableFilters: true,
                },
                {
                  Header: "Subscriptor Promocionado",
                  accessor: "subscriber",
                  disableFilters: true,
                },
                {
                  Header: "Fecha inicio",
                  accessor: "startingDate",
                  disableFilters: true,
                },
                {
                  Header: "Fecha Fin",
                  accessor: "endingDate",
                  disableFilters: true,
                },
                {
                  Header: "Acciones",
                  accessor: "actions",
                },
              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
