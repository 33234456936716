import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'
import { useApp } from 'hooks/useApp'

function AuthRoute ({ children, ...rest }) {
  const { user } = useAuth()
  const { role } = useApp()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && role !== 'regular'
          ? (
              children
            )
          : (
          <Redirect
            to={{
              pathname: '/admin/mis-subscripciones',
              state: { from: location }
            }}
          />
            )
      }
    />
  )
}

export default AuthRoute
