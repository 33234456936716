import React from 'react'
import { useHistory } from 'react-router-dom'
import { emptyRequest, getRequest, postRequest } from '../constants/request'
import { capitalizeFirst } from '../utils'
import { useHttp } from './useHttp'

const empty = emptyRequest()

export function useModel ({
  name,
  id,
  fields = null,
  expand = null,
  extraParams = null,
  redirectOnPost = false,
  path = 'guardar'
}) {
  const [modelRequest, setModelRequest] = React.useState(empty)
  const [model, modelLoading, modelError, refreshModel] = useHttp(modelRequest)

  const [updateRequest, setUpdateRequest] = React.useState(empty)
  const [postResult, postResultLoading, postResultError] = useHttp(
    updateRequest
  )
  const history = useHistory()

  const updateModel = React.useCallback(
    (newModel, alert = true) => {
      if (!postResultLoading) {
        if (newModel.id) {
          newModel = { [`id${capitalizeFirst(name)}`]: newModel.id }
          delete newModel.id
        }
        const updateReq = postRequest(`${name}/${path}`, newModel)
        updateReq.alert = alert
        setUpdateRequest(updateReq)
      }
    },
    [name, postResultLoading, path]
  )

  React.useEffect(() => {
    let mounted = true
    if (mounted && postResult && redirectOnPost && !postResultError) {
      const { pathname } = history.location
      const redirectTo = pathname.split('/').filter((e) => e !== '')
      history.push('/' + redirectTo[0])
    }
    return () => {
      mounted = false
    }
  }, [postResult, redirectOnPost, postResultError, history])

  React.useEffect(() => {
    if (!name || !id) return
    let params = { id }
    if (fields) params = { ...params, fields }
    if (expand) params = { ...params, expand }
    if (extraParams) params = { ...params, ...extraParams }
    const modelReq = getRequest(name, params)
    setModelRequest(modelReq)
  }, [name, id, fields, expand, extraParams])

  return React.useMemo(() => {
    let finalError = {}
    if (modelError) finalError = { ...modelError }
    if (postResultError) finalError = { ...postResultError }
    return {
      model,
      modelLoading,
      modelError: finalError,
      refreshModel,
      updateModel,
      updateModelResult: postResult,
      updateModelLoading: postResultLoading
    }
  }, [
    model,
    modelLoading,
    modelError,
    refreshModel,
    postResult,
    postResultLoading,
    postResultError,
    updateModel
  ])
}
