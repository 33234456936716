import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import styles from 'assets/jss/material-dashboard-pro-react/components/footerStyle.js'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(styles)

export default function Footer (props) {
  const classes = useStyles()
  const { fluid, white } = props
  const container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  })
  const anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white
    })
  const block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  })
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="https://admin.ablyz.app/" className={block}>
                {'INICIO ABLYZ.COM'}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://ablyz.app/" className={block}>
                {'SOBRE NOSOTROS'}
              </a>
            </ListItem>
            {/* <ListItem className={classes.inlineBlock}>
              <a href="#portfolio" className={block}>
                {"Portfolio"}
              </a>
            </ListItem> */}
            {/* <ListItem className={classes.inlineBlock}>
              <a href="#blog" className={block}>
                {"Blog"}
              </a>
            </ListItem> */}
          </List>
        </div>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()}{' '}
          <a
            href="https://www.creative-tim.com?ref=mdpr-footer"
            className={anchor}
            target="_blank" rel="noreferrer"
          >
            {'ablyz.com'}
          </a>
          {', explora y hazte socio!'}
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
}
