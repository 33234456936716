import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Assignment from '@material-ui/icons/Assignment'
import Person from '@material-ui/icons/Person'
import Magnify from '@material-ui/icons/Search'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import ReactTable from 'components/ReactTable/ReactTable.js'
import Download from '@material-ui/icons/CloudDownload'
import CustomModal from 'components/Modal/CustomModal'
import { Divider, Avatar, Badge, Tooltip } from '@material-ui/core'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { useHttp } from 'hooks/useHttp'
import { getReq, emptyRequest } from 'utils'
import subscriptionStatus from 'constants/subscriptionStatus.js'

import XLSX from 'xlsx'
import moment from 'moment'
import Star from '@material-ui/icons/Star'
import StarOutlined from '@material-ui/icons/StarBorderOutlined'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
}

const useStyles = makeStyles(styles)
const emptyReq = emptyRequest()

export default function Referrals () {
  const [data, setData] = React.useState([])
  const [excelLoading, setExcelLoading] = React.useState(false)
  const [detailOpen, setDetailOpen] = React.useState(null)
  const [modelsRequest, setModelsRequest] = React.useState(emptyReq)
  const [models, modelsLoading, modelsError] = useHttp(modelsRequest)
  const classes = useStyles()

  const dataToRow = React.useCallback(
    (prop, key) => {
      const { displayName, subscriptions, invitees } = prop
      let status = 'INACTIVA'
      const tmp = new Array(...subscriptions)
      if (
        tmp.some((s) => {
          const status = s.status
          return status === 1
        })
      ) {
        status = 'ACTIVA'
      }
      return {
        id: key,
        displayName,
        status: <strong>{status}</strong>,
        invitees: (
          <span>
            <strong>{parseInt(invitees).toString()}</strong> invitados
          </span>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Tooltip title="Ver detalle">
              <Button
                justIcon
                round
                simple
                onClick={() => setDetailOpen(prop)}
                color="info"
                className="like"
              >
                <Magnify />
              </Button>
            </Tooltip>
            {/* use this button to add a edit kind of action */}
            <Tooltip title="Ver perfil">
              <Button
                justIcon
                round
                simple
                onClick={() => {}}
                color="success"
                className="edit"
              >
                <Person />
              </Button>
            </Tooltip>
            {/* use this button to remove the data row */}
            {/* <Button
            justIcon
            round
            simple
            onClick={() => {
              var newData = data;
              newData.find((o, i) => {
                if (o.id === key) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  newData.splice(i, 1);
                  return true;
                }
                return false;
              });
              setData([...newData]);
            }}
            color="danger"
            className="remove"
          >
            <Close />
          </Button>{" "} */}
          </div>
        )
      }
    },
    [data, models]
  )

  React.useEffect(() => {
    const loadModelsReq = getReq('users/referrals')
    setModelsRequest(loadModelsReq)
  }, [])

  React.useEffect(() => {
    if (!modelsLoading && !modelsError && models) {
      if (!models.length) return
      // console.log(models.flat((e) => e.referrals));
      const dat = models.map((m) => dataToRow(m, m.id))
      setData([...dat])
    }
  }, [models, modelsLoading, modelsError])

  function generateExcel (entries) {
    setExcelLoading(true)
    const filename = 'Reporte_de_invitados.xlsx'
    const ws_name = 'Visitas'
    const wb = {
      SheetNames: [],
      Sheets: {}
    }
    const ws = {
      A2: { v: 'Nivel', t: 's' },
      B2: { v: 'Nombre completo', t: 's' },
      C2: { v: 'Correo electrónico', t: 's' },
      D2: { v: 'Número de invitados', t: 's' },
      E2: { v: 'Estatus de subscripción', t: 's' },
      F2: { v: 'Nivel de invitados', t: 's' },
      G2: { v: 'Nivel de invitación', t: 's' },
      H2: { v: 'Puntuación', t: 's' },
      I2: { v: 'Puntuación como subscriptor', t: 's' },
      J2: { v: 'Total', t: 's' }
    }
    let renInicial = 3
    for (const i in entries) {
      const {
        firstName,
        lastName,
        email,
        invitees,
        isActive,
        layers,
        nivelId,
        rating,
        subRating
      } = entries[i]
      ws['A' + renInicial] = {
        v: firstName,
        t: 's'
      }
      ws['B' + renInicial] = { v: lastName, t: 's' }
      ws['C' + renInicial] = { v: email, t: 's' }
      ws['D' + renInicial] = { v: invitees, t: 's' }
      ws['E' + renInicial] = {
        v: isActive ? 'Activa' : 'Pendiente de pago',
        t: 's'
      }
      ws['F' + renInicial] = { v: layers, t: 's' }
      ws['G' + renInicial] = { v: nivelId, t: 's' }
      ws['H' + renInicial] = { v: rating, t: 's' }
      ws['I' + renInicial] = { v: subRating, t: 's' }
      ws['J' + renInicial] = { v: 150 * (nivelId * 0.08), t: 's' }
      renInicial++
    }
    const wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }]
    ws['!cols'] = wscols
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial }
    })
    wb.SheetNames.push(ws_name)
    wb.Sheets[ws_name] = ws
    XLSX.writeFile(wb, filename, { cellStyles: true })
    setExcelLoading(false)
  }

  const downloadReportClicked = async () => {
    const flatten = (data) => {
      return data.reduce((r, { referrals, ...rest }) => {
        r.push(rest)
        if (referrals) r.push(...flatten(referrals))
        return r
      }, [])
    }

    const flattened = flatten(models)
    // console.log(flattened);
    generateExcel(flattened)
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify="flex-end">
          <Button
            round
            color="success"
            disabled={excelLoading}
            onClick={downloadReportClicked}
          >
            Descargar reporte
            <Download style={{ marginLeft: 10 }} />
          </Button>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              HISTORIAL Y RELACIONES DE INVITACIÓN DE USUARIOS
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              loading={modelsLoading}
              columns={[
                {
                  Header: 'Nombre(s) y Apellido(s)',
                  accessor: 'displayName'
                },
                {
                  Header: 'Estatus Subscripción',
                  accessor: 'status'
                },
                {
                  Header: 'Número de invitados',
                  accessor: 'invitees'
                },
                {
                  Header: 'Acciones',
                  accessor: 'actions'
                }
              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
      <CustomModal
        open={Boolean(detailOpen)}
        onCancel={() => setDetailOpen(null)}
        onAccept={() => setDetailOpen(null)}
        title={detailOpen?.displayName}
      >
        <GridContainer direction="row">
          <GridItem xs={8}>
            <GridContainer direction="column">
              <GridItem xs={12}>
                <h6>Correo electrónico</h6>
              </GridItem>
              <GridItem xs={12}>
                <h5>{detailOpen?.email}</h5>
              </GridItem>
              <GridItem xs={12}>
                <h4>
                  <strong>
                    {detailOpen?.subscriptions?.length === 0
                      ? 'Ninguna subscripción activa'
                      : `${detailOpen?.subscriptions.length} subscripción(es) activa(s)`}
                  </strong>
                </h4>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={4}>
            <Badge
              color={detailOpen?.isActive ? 'primary' : 'secondary'}
              badgeContent="desconectado"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <Avatar
                style={{ height: 100, width: 100 }}
                src={detailOpen?.qrCodeUrl || ''}
              />
            </Badge>
          </GridItem>
          <GridItem xs={12}>
            <Divider />
          </GridItem>

          <GridItem xs={12}>
            <h4 style={{ verticalAlign: 'center' }}>
              Puntuación:{' '}
              {[1, 2, 3, 4, 5].map((e, index) =>
                e <= detailOpen?.rating
                  ? (
                  <Star key={index} />
                    )
                  : (
                  <StarOutlined key={index} />
                    )
              )}
            </h4>
          </GridItem>
          <GridItem xs={12}>
            <h4 style={{ verticalAlign: 'center' }}>
              Servicios ofrecidos:{' '}
              {detailOpen?.services?.length > 0
                ? `${detailOpen.services.length} Servicio(s)`
                : 'Ningún servicio'}
            </h4>
          </GridItem>

          <GridItem xs={12}>
            <h4 style={{ verticalAlign: 'center' }}>
              Nivel en sistema de Referidos: {detailOpen?.nivelId}
            </h4>
          </GridItem>
          <GridItem xs={12}>
            <h4 style={{ verticalAlign: 'center' }}>
              Número de invitados: {detailOpen?.invitees}
            </h4>
          </GridItem>
        </GridContainer>
      </CustomModal>
    </GridContainer>
  )
}
