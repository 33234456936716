import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import { v4 as uuidv4 } from 'uuid'

import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'

import { useQuery } from 'hooks/useQuery'
import { emptyRequest, getReq } from 'utils'
import { useModel } from 'hooks/useModel'

import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardText from 'components/Card/CardText.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardBody from 'components/Card/CardBody.js'
import ImageUpload from 'components/CustomUpload/ImageUpload.js'
import httpService from 'utils/httpService'
import { useModels } from 'hooks/useModels'
import { storage } from '../../firebase'

const useStyles = makeStyles(styles)

const emptyReq = emptyRequest()
const getPrimaryCategoriesRequest = getReq('categories', {
  primary: true
})
const extraParams = { size: 500 }
const CategoriesDetail = ({ history }) => {
  const classes = useStyles()
  const query = useQuery()
  const categoryId = query.get('id')
  const { model: category, modelLoading: categoryLoading } = useModel({
    name: 'categories',
    id: categoryId || null
  })
  const [categories, categoriesLoading] = useModels({
    name: 'categories',
    extraParams
  })
  const [selectedCategory, setSelectedCategory] = React.useState('')
  // Nombre categoria
  const [name, setName] = React.useState('')
  const [nameValidationStatus, setNameValidationStatus] = React.useState('')
  // Descripcion categoria
  const [description, setDescription] = React.useState('')
  const [
    descriptionValidationStatus,
    setDescriptionValidationStatus
  ] = React.useState('')
  // Imágen primaria
  const [primaryImgUrl, setPrimaryImgUrl] = React.useState('')
  const [
    primaryImgUrlValidationStatus,
    setPrimaryImgUrlValidationStatus
  ] = React.useState('')

  const [isPrimary, setIsPrimary] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (categories && !categoriesLoading && category && !categoryLoading) {
      setSelectedCategory(categories?.find(c => c?.id === category?.parentId))
      setIsPrimary(!category.parentId)
      setName(category.name)
      setDescription(category.description)
      setPrimaryImgUrl(category.photo)
    }
  }, [categories, categoriesLoading, category, categoryLoading])

  const saveClicked = async () => {
    let body = {}
    let photoUrl = null

    try {
      setLoading(true)
      if (name === '') {
        setNameValidationStatus('error')
      }
      if (description === '') {
        setDescriptionValidationStatus('error')
      }
      if (!primaryImgUrl) {
        setPrimaryImgUrlValidationStatus('error')
      }
      if (name === '' || description === '' || !primaryImgUrl) return

      if (primaryImgUrl && primaryImgUrl.file) {
        await storage.ref().child(`categories/${primaryImgUrl.file.name}`).put(primaryImgUrl.file)
        const downloadUrl = await storage
          .ref()
          .child(`categories/${primaryImgUrl.file.name}`)
          .getDownloadURL()
        if (typeof downloadUrl === 'string') photoUrl = downloadUrl
      }

      if (name) body = { ...body, name }
      if (description) body = { ...body, description }
      if (selectedCategory?.id) body = { ...body, parentId: selectedCategory.id }
      if (isPrimary) body = { ...body, parentId: null }
      if (photoUrl) body = { ...body, photo: photoUrl }; else if (typeof primaryImgUrl === 'string' && !photoUrl) body = { ...body, photo: primaryImgUrl }
      if (categoryId) body = { ...body, id: categoryId }

      const resp = categoryId ? await httpService.patch('categories', body) : await httpService.post('categories', body)
      if (resp?.id) {
        history.push('/admin/categorias')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSimple = (event) => {
    setSelectedCategory(event.target.value)
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={8} sm={8} md={8}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h3 className={classes.cardTitle}>
                {categoryId
                  ? (
                  <>
                    <strong>Editando</strong> categoría
                  </>
                    )
                  : (
                  <>
                    <strong>Nueva</strong> categoría
                  </>
                    )}
              </h3>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer spacing={2}>
                <GridItem xs={6}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      ' ' +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          // tabIndex={-1}
                          checked={isPrimary}
                          onClick={() => setIsPrimary((val) => !val)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Categoría primaria"
                    />
                  </div>
                </GridItem>
                <GridItem xs={6}>
                  {!isPrimary && (
                    <Select
                      fullWidth
                      disabled={categoriesLoading}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={selectedCategory?.id}
                      onChange={handleSimple}
                      inputProps={{
                        name: 'primaryCategory',
                        id: 'primary-category'
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Seleccionar categoría padre
                      </MenuItem>
                      {categories &&
                        categories.map((primaryCategory) => (
                          <MenuItem
                            key={primaryCategory.id}
                            value={primaryCategory.id}
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {primaryCategory.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                </GridItem>
              </GridContainer>

              <CustomInput
                success={nameValidationStatus === 'success'}
                error={nameValidationStatus === 'error'}
                labelText="Nombre de categoría*"
                id="categoryName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: name,
                  onChange: (event) => {
                    if (event.target.value !== '') {
                      setNameValidationStatus('success')
                    } else {
                      setNameValidationStatus('error')
                    }
                    setName(event.target.value)
                  }
                }}
              />
              <CustomInput
                success={descriptionValidationStatus === 'success'}
                error={descriptionValidationStatus === 'error'}
                labelText="Descripción de categoría*"
                id="categoryDescription"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: description,
                  onChange: (event) => {
                    if (event.target.value !== '') {
                      setDescriptionValidationStatus('success')
                    } else {
                      setDescriptionValidationStatus('error')
                    }
                    setDescription(event.target.value)
                  }
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                  <legend>
                    Imágen principal
                  </legend>
                  {/* {JSON.stringify({ primaryImgUrl })} */}
                  <ImageUpload
                    loadedUrl={primaryImgUrl?.base64 || primaryImgUrl }
                    addButtonProps={{
                      color: 'rose',
                      round: true
                    }}
                    changeButtonProps={{
                      color: 'rose',
                      round: true
                    }}
                    removeButtonProps={{
                      color: 'danger',
                      round: true
                    }}
                    onFileChanged={(file) => { setPrimaryImgUrl(file) }}
                  />
                  {primaryImgUrlValidationStatus === 'error' && (
                    <Typography color="error">* Imágen requerida</Typography>
                  )}
                </GridItem>
                {/* <GridItem xs={12} sm={4} md={4}>
                  <legend>
                    <strong>Imágen secundaria</strong>
                  </legend>
                  <ImageUpload
                    defaultPath={secondaryImgUrl}
                    addButtonProps={{
                      color: "rose",
                      round: true,
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true,
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true,
                    }}
                    onFileChanged={(file) => setSecondaryImgUrl(file)}
                  />
                </GridItem> */}
              </GridContainer>

              <Button
                color="success"
                onClick={saveClicked}
                className={classes.registerButton}
                disabled={loading || name === '' || description === ''}
              >
                {loading ? 'Cargando...' : 'Guardar categoría'}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default CategoriesDetail
