import React from 'react'

import defaultImage from 'assets/img/default-avatar.png'

export default function PictureUpload ({ url, onFileChanged }) {
  const [file, setFile] = React.useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    url !== null && url !== '' ? url : defaultImage
  )
  const handleImageChange = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    const newFile = e.target.files[0]
    reader.onloadend = () => {
      setFile(newFile)
      setImagePreviewUrl(reader.result)
    }
    if (newFile) {
      reader.readAsDataURL(newFile)
    }
  }

  React.useEffect(() => {
    if (onFileChanged && file) {
      onFileChanged(file)
    }
  }, [file, onFileChanged])

  return (
    <div className="picture-container">
      <div className="picture">
        <img src={url} className="picture-src" alt="..." />
        <input type="file" onChange={(e) => handleImageChange(e)} />
      </div>
      <h6 className="description">Elegir fotografía</h6>
    </div>
  )
}
