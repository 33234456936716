import React from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { AppProvider } from 'hooks/useApp'
import { AuthProvider, useAuth } from 'hooks/useAuth'
import { AlertProvider, useAlert } from 'hooks/useAlert'
import PrivateRouter from 'routers/PrivateRouter'
import PublicRouter from 'routers/PublicRouter'

const AppRouting = () => {
  const { session, sessionLoading } = useAuth()
  const { open, position, severity, message } = useAlert()

  const AlertMemo = React.useMemo(
    () => (
      <Snackbar open={open} anchorOrigin={position}>
        <Alert severity={severity} elevation={6} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    ),
    [open, position, severity, message]
  )

  if (sessionLoading) return null
  return (
    <>
      {session ? <PrivateRouter /> : <PublicRouter />}
      {AlertMemo}
    </>
  )
}

const App = () => {
  return (
    <AppProvider>
      <AlertProvider>
        <AuthProvider>
          <AppRouting />
        </AuthProvider>
      </AlertProvider>
    </AppProvider>
  )
}

export default App
