import React from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import Button from 'components/CustomButtons/Button.js'
import moment from 'moment'
import { IconButton } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import ArrowLeft from '@material-ui/icons/ArrowLeftRounded'
import ArrowRight from '@material-ui/icons/ArrowRightRounded'

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1)
    toolbar.onNavigate('prev')
  }

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1)
    toolbar.onNavigate('next')
  }

  const goToCurrent = () => {
    const now = new Date()
    toolbar.date.setMonth(now.getMonth())
    toolbar.date.setYear(now.getFullYear())
    toolbar.onNavigate('current')
  }

  const label = () => {
    const date = moment(toolbar.date)
    return (
      <span>
        <b style={{ fontSize: 28 }}>{date.format('MMMM')}</b>
        <span style={{ fontSize: 28 }}> {date.format('YYYY')}</span>
      </span>
    )
  }

  return (
    <div className={sCalendar['toolbar-container']}>
      <div className={sCalendar['back-next-buttons']} style={{ width: '100%' }}>
        <Button
          color="info"
          className={sCalendar['btn-back']}
          onClick={goToBack}
          startIcon={<ArrowLeft />}
        >
          Atrás
        </Button>
        <Button
          color="info"
          className={sCalendar['btn-current']}
          onClick={goToCurrent}
        >
          Día de Hoy
        </Button>
        <Button
          color="info"
          className={sCalendar['btn-next']}
          onClick={goToNext}
          endIcon={<ArrowRight />}
        >
          Siguiente
        </Button>
        <label className={sCalendar['label-date']} style={{ marginLeft: 48 }}>
          {label()}
        </label>
      </div>
    </div>
  )
}

moment.locale('es')
const localizer = momentLocalizer(moment)
const sCalendar = {}
const CustomCalendar = (props) => {
  return (
    <div className={sCalendar.layout}>
      <BigCalendar
        localizer={localizer}
        events={props.events}
        views={{ month: true, week: true }}
        timeslots={4}
        components={{
          toolbar: CustomToolbar
        }}
        onSelectEvent={props.onSelectEvent}
      />
    </div>
  )
}

export default CustomCalendar
