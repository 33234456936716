import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }
  render() {
    const { classes, subscription, category } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h3 className={classes.infoText}>Confirmación de suscripción</h3>
        </GridItem>

        <GridItem xs={6} sm={6}>
          <h4 className={classes.infoText}>
            <strong>Subscripción por comprar</strong>
          </h4>
          <h5 className={classes.infoText}>
            {subscription ? subscription.name : "n/a"}
          </h5>
        </GridItem>

        <GridItem xs={6} sm={3}>
          <h4 className={classes.infoText}>
            <strong>Categoría</strong>
          </h4>
          <h5 className={classes.infoText}>
            {category ? category.name : "n/a"}
          </h5>
        </GridItem>
        <GridItem xs={6} sm={3}>
          <h4 className={classes.infoText}>
            <strong>Precio</strong>
          </h4>
          <h5 className={classes.infoText}>
            {subscription
              ? "MXN $" + (subscription.price || 0).toFixed(2)
              : "n/a"}
          </h5>
        </GridItem>
        {/* <GridItem xs={12} sm={7}>
          <CustomInput
            labelText="Street Name"
            id="streetname"
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
         */}
        {/* <GridItem xs={12} sm={3}>
          <CustomInput
            labelText="Street No."
            id="streetno"
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
         */}
        {/* <GridItem xs={12} sm={5}>
          <CustomInput
            labelText="City"
            id="city"
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
         */}
        {/* <GridItem xs={12} sm={5}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Choose City
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.simpleSelect}
              onChange={this.handleSimple}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Country
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="2"
              >
                France
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="3"
              >
                Romania
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
       */}
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step3);
