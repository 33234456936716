import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from '@material-ui/icons/Home'
import Business from '@material-ui/icons/Business'
import AccountBalance from '@material-ui/icons/AccountBalance'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js'

const useStyles = makeStyles(styles)

export default function PricingPage ({ history }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Encuentra la mejor oferta para tí!</h2>
          <h5 className={classes.description}>
            Únete y se parte de la red ABLYZ para contratar y buscar todo tipo
            de servicios para sus necesidades.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h6 className={classes.cardCategory}>CUENTA GRATIS</h6>
              <div className={classes.icon}>
                <Icon className={classes.iconWhite}>weekend</Icon>
              </div>
              <h3
                className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                GRATIS
              </h3>
              <p className={classes.cardCategory}>
                Busca y contrata todo tipo de servicios disponibles en tu
                localización.
              </p>
              <Button
                round
                color="white"
                onClick={() => history.push('/registro')}
              >
                CUENTA GRATIS
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>SMALL COMPANY</h6>
              <div className={classes.icon}>
                <Home className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                $29
              </h3>
              <p className={classes.cardDescription}>
                This is good if your company size is between 2 and 10 Persons.
              </p>
              <Button round color="rose">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>SOCIO ABLYZ</h6>
              <div className={classes.icon}>
                <Business className={classes.iconPrimary} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                $10.00 USD
              </h3>
              <p className={classes.cardCategory}>
                Conviertete en socio Ablyz y sé parte de la red mas grande de
                provedores de servicios.
              </p>
              <Button
              round
              color="primary"
              onClick={() => history.push('/registro')}
              >
                COMPRAR SUBSCRIPCIÓN
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={3}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h6 className={classes.cardCategory}>ENTERPRISE</h6>
              <div className={classes.icon}>
                <AccountBalance className={classes.iconWhite} />
              </div>
              <h3
                className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                $159
              </h3>
              <p className={classes.cardCategory}>
                This is good if your company size is 99+ persons.
              </p>
              <Button round color="white">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  )
}
