import AuthRoute from './AuthRoute'

const capitalizeFirst = (string) => {
  const split = string.split('-')
  let palabraUnida = ''
  split.forEach((s) => {
    palabraUnida = palabraUnida + s.charAt(0).toUpperCase() + s.slice(1)
  })
  return palabraUnida
}
const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const validateRfc = (rfcString) => {
  const re = /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i
  return re.test(String(rfcString).toLowerCase())
}

const validateName = (name) => {
  const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
  return re.test(name)
}

const validateUser = (user) => {
  const re = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
  return re.test(user)
}

const validatePassword = (passwd) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
  return re.test(passwd)
}

const validateConfirmPassword = (passwd, passwd2) => {
  if (passwd2 !== passwd) return false
  else return true
}

const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return phoneNumberString
}

const propertyAccesor = (rootObj, accesor = '') => {
  const properties = accesor.split('.')
  let tmp = rootObj
  properties.forEach((prop) => (tmp = tmp[prop]))
  return tmp
}

export * from './requests'

export {
  AuthRoute,
  capitalizeFirst,
  validateEmail,
  validateRfc,
  validateName,
  validateUser,
  validatePassword,
  validateConfirmPassword,
  formatPhoneNumber,
  propertyAccesor
}
