import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Assignment from '@material-ui/icons/Assignment'
import Dvr from '@material-ui/icons/Dvr'
import Info from '@material-ui/icons/Info'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import ReactTable from 'components/ReactTable/ReactTable.js'
import AddIcon from '@material-ui/icons/Add'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { useHttp } from 'hooks/useHttp'
import { getReq, emptyRequest } from 'utils'

import XLSX from 'xlsx'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
}

const useStyles = makeStyles(styles)
const emptyReq = emptyRequest()

export default function MyServices ({ history }) {
  const [data, setData] = React.useState([])
  const [excelLoading, setExcelLoading] = React.useState(false)
  const [modelsRequest, setModelsRequest] = React.useState(emptyReq)
  const [models, modelsLoading, modelsError] = useHttp(modelsRequest)
  const classes = useStyles()

  const dataToRow = React.useCallback(
    (prop, key) => {
      return {
        id: key,
        name: <strong>{prop.name}</strong>,
        price: <strong>{prop.price}</strong>,
        description: prop.description,
        photo: (
          <GridContainer justify="center">
            <GridItem>
              {prop.photos && prop.photos[0]
                ? (
                <img
                  style={{ height: 60 }}
                  src={prop.photos[0]}
                  alt={prop.name.toLowerCase()}
                />
                  )
                : (
                <i>Sin imágen</i>
                  )}
            </GridItem>
          </GridContainer>
        ),
        detail: (
          <>
            <strong>{prop.categories.length}</strong> Categorías
          </>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => history.push('/admin/mis-servicios?id=' + key)}
              color="info"
              className="like"
            >
              <Info />
            </Button>{' '}
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                const obj = data.find((o) => o.id === key)
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>
            {/* use this button to remove the data row */}
            {/* <Button
            justIcon
            round
            simple
            onClick={() => {
              var newData = data;
              newData.find((o, i) => {
                if (o.id === key) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  newData.splice(i, 1);
                  return true;
                }
                return false;
              });
              setData([...newData]);
            }}
            color="danger"
            className="remove"
          >
            <Close />
          </Button>{" "} */}
          </div>
        )
      }
    },
    [data, models]
  )

  React.useEffect(() => {
    const loadModelsReq = getReq('services?expand=media')
    setModelsRequest(loadModelsReq)
  }, [])

  React.useEffect(() => {
    if (!modelsLoading && !modelsError && models) {
      if (!models.length) return
      // console.log(models.flat((e) => e.referrals));
      const mapped = models.map((m) => dataToRow(m, m.id))

      console.log(mapped)
      setData(mapped)
    }
  }, [models, modelsLoading, modelsError])

  function generateExcel (entries) {
    setExcelLoading(true)
    const filename = 'Reporte_de_invitados.xlsx'
    const ws_name = 'Visitas'
    const wb = {
      SheetNames: [],
      Sheets: {}
    }
    const ws = {
      A2: { v: 'Nivel', t: 's' },
      B2: { v: 'Nombre completo', t: 's' },
      C2: { v: 'Correo electrónico', t: 's' },
      D2: { v: 'Fecha de corte', t: 's' }
      // E2: { v: "Código postal", t: "s" },
      // F2: { v: "Celular", t: "s" },
      // G2: { v: "Distrito Federal", t: "s" },
      // H2: { v: "Distrito Local", t: "s" },
      // I2: { v: "Sección", t: "s" },
      // J2: { v: "Nombre de promotor", t: "s" },
      // K2: { v: "Correo de promotor", t: "s" },
      // L2: { v: "Grupo", t: "s" },
      // M2: { v: "Fecha de visita", t: "s" },
    }
    let renInicial = 3
    for (const i in entries) {
      const {
        firstName,
        lastName,
        email,
        createdOn
        // codigoPostal,
        // celular,
        // nombreDistritoFederal,
        // nombreDistritoLocal,
        // nombreSeccion,
        // nombrePromotor,
        // correoPromotor,
        // grupo,
        // fechaVisita,
      } = entries[i]
      // let fechaVisitaFormatted = moment(fechaVisita)
      //   .tz(tz)
      //   .format("DD/MM/YYYY");
      ws['A' + renInicial] = {
        v: firstName,
        t: 's'
      }
      ws['B' + renInicial] = { v: lastName, t: 's' }
      ws['C' + renInicial] = { v: email, t: 's' }
      ws['D' + renInicial] = { v: createdOn.toString(), t: 's' }
      // ws["E" + renInicial] = { v: codigoPostal, t: "s" };
      // ws["F" + renInicial] = { v: celular, t: "s" };
      // ws["G" + renInicial] = { v: nombreDistritoFederal, t: "s" };
      // ws["H" + renInicial] = { v: nombreDistritoLocal, t: "s" };
      // ws["I" + renInicial] = { v: nombreSeccion, t: "s" };
      // ws["J" + renInicial] = { v: nombrePromotor, t: "s" };
      // ws["K" + renInicial] = { v: correoPromotor, t: "s" };
      // ws["L" + renInicial] = { v: grupo, t: "s" };
      // ws["M" + renInicial] = { v: fechaVisitaFormatted, t: "s" };
      renInicial++
    }
    const wscols = [
      { wch: 30 },
      { wch: 40 },
      { wch: 35 },
      { wch: 35 }
      // { wch: 10 },
      // { wch: 15 },
      // { wch: 25 },
      // { wch: 25 },
      // { wch: 15 },
      // { wch: 30 },
      // { wch: 30 },
      // { wch: 30 },
      // { wch: 15 },
    ]
    ws['!cols'] = wscols
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial }
    })
    wb.SheetNames.push(ws_name)
    wb.Sheets[ws_name] = ws
    XLSX.writeFile(wb, filename, { cellStyles: true })
    setExcelLoading(false)
  }

  const downloadReportClicked = async () => {
    const flatten = (data) => {
      return data.reduce((r, { referrals, ...rest }) => {
        r.push(rest)
        if (referrals) r.push(...flatten(referrals))
        return r
      }, [])
    }

    const flattened = flatten(models)
    // console.log(flattened);
    generateExcel(flattened)
  }

  if (modelsLoading) return null

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify="flex-end">
          <Button
            round
            color="success"
            disabled={excelLoading}
            onClick={() => history.push('/admin/servicio')}
          >
            Agregar servicio
            <AddIcon style={{ marginLeft: 10 }} />
          </Button>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h3 className={classes.cardIconTitle}>
              Administración y control de mis servicios
            </h3>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: 'Nombre',
                  accessor: 'name'
                },
                {
                  Header: 'Descripción',
                  accessor: 'description',
                  disableFilters: true
                },
                {
                  Header: 'Fotografía principal',
                  accessor: 'photo',
                  disableFilters: true
                },
                {
                  Header: 'Detalle de relación',
                  accessor: 'detail',
                  disableFilters: true
                },
                {
                  Header: 'Acciones',
                  accessor: 'actions'
                }
              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

// import React from "react";
// // @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import Checkbox from "@material-ui/core/Checkbox";

// // material-ui icons
// import Assignment from "@material-ui/icons/Assignment";
// import Person from "@material-ui/icons/Person";
// import Edit from "@material-ui/icons/Edit";
// import Close from "@material-ui/icons/Close";
// import Check from "@material-ui/icons/Check";
// import Remove from "@material-ui/icons/Remove";
// import Add from "@material-ui/icons/Add";
// import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// // core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardHeader from "components/Card/CardHeader.js";

// import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// import product1 from "assets/img/product1.jpg";
// import product2 from "assets/img/product2.jpg";
// import product3 from "assets/img/product3.jpg";

// const useStyles = makeStyles(styles);

// const Services = () => {
//   const classes = useStyles();

//   return (
//     <GridContainer>
//       <GridItem xs={12}>
//         <Card>
//           <CardHeader color="rose" icon>
//             <CardIcon color="rose">
//               <Assignment />
//             </CardIcon>
//             <h4 className={classes.cardIconTitle}>Tus servicios</h4>
//           </CardHeader>
//           <CardBody>
//             <Table
//               tableHead={[
//                 "",
//                 "PRODUCT",
//                 "COLOR",
//                 "SIZE",
//                 "PRICE",
//                 "QTY",
//                 "AMOUNT",
//                 "",
//               ]}
//               tableData={[
//                 [
//                   <div className={classes.imgContainer} key="key">
//                     <img src={product1} alt="..." className={classes.img} />
//                   </div>,
//                   <span key="key">
//                     <a href="#jacket" className={classes.tdNameAnchor}>
//                       Spring Jacket
//                     </a>
//                     <br />
//                     <small className={classes.tdNameSmall}>
//                       by Dolce&amp;Gabbana
//                     </small>
//                   </span>,
//                   "Red",
//                   "M",
//                   <span key="key">
//                     <small className={classes.tdNumberSmall}>€</small> 549
//                   </span>,
//                   <span key="key">
//                     1{` `}
//                     <div className={classes.buttonGroup}>
//                       <Button
//                         color="info"
//                         size="sm"
//                         round
//                         className={classes.firstButton}
//                       >
//                         <Remove className={classes.icon} />
//                       </Button>
//                       <Button
//                         color="info"
//                         size="sm"
//                         round
//                         className={classes.lastButton}
//                       >
//                         <Add className={classes.icon} />
//                       </Button>
//                     </div>
//                   </span>,
//                   <span key="key">
//                     <small className={classes.tdNumberSmall}>€</small> 549
//                   </span>,
//                   <Button simple className={classes.actionButton} key="key">
//                     <Close className={classes.icon} />
//                   </Button>,
//                 ],
//                 [
//                   <div className={classes.imgContainer} key="key">
//                     <img src={product2} alt="..." className={classes.img} />
//                   </div>,
//                   <span key="key">
//                     <a href="#jacket" className={classes.tdNameAnchor}>
//                       Short Pants{" "}
//                     </a>
//                     <br />
//                     <small className={classes.tdNameSmall}>by Pucci</small>
//                   </span>,
//                   "Purple",
//                   "M",
//                   <span key="key">
//                     <small className={classes.tdNumberSmall}>€</small> 499
//                   </span>,
//                   <span key="key">
//                     2{` `}
//                     <div className={classes.buttonGroup}>
//                       <Button
//                         color="info"
//                         size="sm"
//                         round
//                         className={classes.firstButton}
//                       >
//                         <Remove className={classes.icon} />
//                       </Button>
//                       <Button
//                         color="info"
//                         size="sm"
//                         round
//                         className={classes.lastButton}
//                       >
//                         <Add className={classes.icon} />
//                       </Button>
//                     </div>
//                   </span>,
//                   <span key="key">
//                     <small className={classes.tdNumberSmall}>€</small> 998
//                   </span>,
//                   <Button simple className={classes.actionButton} key="key">
//                     <Close className={classes.icon} />
//                   </Button>,
//                 ],
//                 [
//                   <div className={classes.imgContainer} key="key">
//                     <img src={product3} alt="..." className={classes.img} />
//                   </div>,
//                   <span key="key">
//                     <a href="#jacket" className={classes.tdNameAnchor}>
//                       Pencil Skirt
//                     </a>
//                     <br />
//                     <small className={classes.tdNameSmall}>by Valentino</small>
//                   </span>,
//                   "White",
//                   "XL",
//                   <span key="key">
//                     <small className={classes.tdNumberSmall}>€</small> 799
//                   </span>,
//                   <span key="key">
//                     1{` `}
//                     <div className={classes.buttonGroup}>
//                       <Button
//                         color="info"
//                         size="sm"
//                         round
//                         className={classes.firstButton}
//                       >
//                         <Remove className={classes.icon} />
//                       </Button>
//                       <Button
//                         color="info"
//                         size="sm"
//                         round
//                         className={classes.lastButton}
//                       >
//                         <Add className={classes.icon} />
//                       </Button>
//                     </div>
//                   </span>,
//                   <span key="key">
//                     <small className={classes.tdNumberSmall}>€</small> 799
//                   </span>,
//                   <Button simple className={classes.actionButton} key="key">
//                     <Close className={classes.icon} />
//                   </Button>,
//                 ],
//                 {
//                   total: true,
//                   colspan: "5",
//                   amount: (
//                     <span key="key">
//                       <small>€</small>2,346
//                     </span>
//                   ),
//                 },
//                 {
//                   purchase: true,
//                   colspan: "6",
//                   col: {
//                     colspan: 2,
//                     text: (
//                       <Button color="info" round>
//                         Complete Purchase{" "}
//                         <KeyboardArrowRight className={classes.icon} />
//                       </Button>
//                     ),
//                   },
//                 },
//               ]}
//               tableShopping
//               customHeadCellClasses={[
//                 classes.center,
//                 classes.description,
//                 classes.description,
//                 classes.right,
//                 classes.right,
//                 classes.right,
//               ]}
//               customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
//               customCellClasses={[
//                 classes.tdName,
//                 classes.customFont,
//                 classes.customFont,
//                 classes.tdNumber,
//                 classes.tdNumber + " " + classes.tdNumberAndButtonGroup,
//                 classes.tdNumber,
//               ]}
//               customClassesForCells={[1, 2, 3, 4, 5, 6]}
//             />
//           </CardBody>
//         </Card>
//       </GridItem>
//     </GridContainer>
//   );
// };

// export default Services;
