import React from 'react'

const AppContext = React.createContext()

export function AppProvider (props) {
  const [appLoading, setAppLoading] = React.useState(true)
  const [token, setToken] = React.useState(null)
  const [role, setRole] = React.useState(null)

  const memData = React.useMemo(() => {
    return { appLoading, token, setToken, role, setRole }
  }, [appLoading, token, setToken, role])

  return <AppContext.Provider value={memData} {...props} />
}

export function useApp () {
  const context = React.useContext(AppContext)
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw 'error: app context not defined.'
  }
  return context
}
