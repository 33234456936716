import firebase from 'firebase/app'

const { NODE_ENV: env } = process.env

const baseUrl =
  env === 'development'
    ? process.env.REACT_APP_DEV_API_URL
    : process.env.REACT_APP_PRODUCTION_API_URL

const getCurrentToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      unsubscribe()
      if (user) {
        const token = await user.getIdToken()
        resolve(token)
      }
    }, reject)
  })
}

const getHeaders = (token) => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${token}`
})

const getHeadersWithoutToken = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json'
})

const paramsToQuery = (params) =>
  Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    )
    .join('&')

const httpService = {
  get: async (url, auth = true) => {
    let token = null
    if (auth) token = await getCurrentToken()
    const response = await fetch(baseUrl + url, {
      method: 'GET',
      headers: auth ? getHeaders(token) : getHeadersWithoutToken()
    })

    return response.json()
  },
  post: async (url, data, auth = true) => {
    let token = null
    if (auth) token = await getCurrentToken()
    const response = await fetch(baseUrl + url, {
      method: 'POST',
      headers: auth ? getHeaders(token) : getHeadersWithoutToken(),
      body: JSON.stringify(data)
    })
    return response.json()
  },
  patch: async (url, data, auth = true) => {
    let token = null
    if (auth) token = await getCurrentToken()
    const response = await fetch(baseUrl + url, {
      method: 'PATCH',
      headers: auth ? getHeaders(token) : getHeadersWithoutToken(),
      body: JSON.stringify(data)
    })
    return response.json()
  },
  delete: async (url, params, auth = true) => {
    let token = null
    if (auth) token = await getCurrentToken()
    const str = `${baseUrl}${url}${
      params && Object.keys(params).length > 0
        ? `?${paramsToQuery(params)}`
        : ''
    }`
    const response = await fetch(str, {
      method: 'DELETE',
      headers: auth ? getHeaders(token) : getHeadersWithoutToken()
    })

    return response.json()
  }
}

export default httpService
