import React from 'react'
import Dvr from '@material-ui/icons/Dvr'
import { makeStyles } from '@material-ui/core/styles'

import { useHttp } from 'hooks/useHttp'
import httpService from 'utils/httpService'
import { getReq, emptyRequest } from 'utils'

import ReactTable from 'components/ReactTable/ReactTable'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardText from 'components/Card/CardText'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import CustomModal from 'components/Modal/CustomModal'
import CustomInput from 'components/CustomInput/CustomInput'
import SweetAlert from 'react-bootstrap-sweetalert'

const emptyReq = emptyRequest()

const styles = {}

const useStyles = makeStyles(styles)

const NotificationSender = () => {
  const [data, setData] = React.useState([])
  const [modelsRequest, setModelsRequest] = React.useState(emptyReq)
  const [models, modelsLoading, modelsError] = useHttp(modelsRequest)
  const [notifTitle, setNotifTitle] = React.useState('')
  const [notifBody, setNotifBody] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState(null)
  const classes = useStyles()

  const dataToRow = React.useCallback(
    (prop, key) => {
      return {
        id: key,
        email: <strong>{prop.email}</strong>,
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => setSelectedUser(prop)}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>
          </div>
        )
      }
    },
    [data, models]
  )

  React.useEffect(() => {
    if (selectedUser === null) {
      setNotifTitle('')
      setNotifBody('')
    }
  }, [selectedUser])

  const sendNotification = React.useCallback(
    async (userId) => {
      try {
        const body = {
          userId,
          title: notifTitle,
          body: notifBody
        }
        const res = await httpService.post('notifications/send', body)
        if (res?.sent === true) {
          alert('Notificación enviada')
        }
      } catch (error) {
        console.error(error)
      }
    },
    [notifTitle, notifBody]
  )

  React.useEffect(() => {
    const loadModelsReq = getReq('users')
    setModelsRequest(loadModelsReq)
  }, [])

  React.useEffect(() => {
    if (!modelsLoading && !modelsError && models) {
      if (!models.length) return
      // console.log(models.flat((e) => e.referrals));
      const mapped = models.map((m) => dataToRow(m, m.id))
      console.log(mapped)
      setData(mapped)
    }
  }, [models, modelsLoading, modelsError])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h3 className={classes.cardTitle}>Enviar notificación</h3>
            </CardText>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: 'Correo electrónico',
                  accessor: 'email'
                },
                {
                  Header: 'Acciones',
                  accessor: 'actions'
                }
              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
      <CustomModal
        open={Boolean(selectedUser)}
        onCancel={() => setSelectedUser(null)}
        onAccept={async () => await sendNotification(selectedUser?.id)}
        title={`Enviar notificación a ${selectedUser?.displayName ?? ''}`}
      >
        <GridContainer direction="row">
          <GridItem xs={12}>
            <CustomInput
              formControlProps={{ style: { width: '100%' } }}
              labelText="Titulo"
              inputProps={{
                onChange: (e) => setNotifTitle(e.target.value),
                value: notifTitle
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <CustomInput
              formControlProps={{ style: { width: '100%' } }}
              labelText="Contenido"
              inputProps={{
                onChange: (e) => setNotifBody(e.target.value),
                value: notifBody
              }}
            />
          </GridItem>
        </GridContainer>
      </CustomModal>
    </GridContainer>
  )
}

export default NotificationSender
