import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'

import Email from '@material-ui/icons/Email'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardFooter from 'components/Card/CardFooter.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js'
import { useAuth } from 'hooks/useAuth'
import { validateEmail, validatePassword } from 'utils'

const useStyles = makeStyles(styles)

export default function LoginPage () {
  const classes = useStyles()
  const [validando, setValidando] = React.useState(false)
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  const { signIn, signInWithFacebook, signInLoading } = useAuth()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const buttonRef = React.useRef(null)

  React.useEffect(() => {
    const id = setTimeout(function () {
      setCardAnimation('')
    }, 500)
    return function cleanup () {
      window.clearTimeout(id)
    }
  })

  const loginClicked = async (event) => {
    event.preventDefault()
    setValidando(true)
    signIn(email, password)
  }

  const isValid = () => {
    return (
      email !== '' &&
      password !== '' &&
      validateEmail(email) &&
      validatePassword(password)
    )
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>
                  Inicia sesión con tu cuenta
                </h4>
                <div className={classes.socialLine}>
                  {[
                    {
                      icon: 'fab fa-google',
                      onClick: signInWithFacebook
                    },
                    { icon: 'fab fa-facebook-square', onClick: null },
                    { icon: 'fab fa-apple', onClick: null }
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                        onClick={prop.onClick}
                      >
                        <i className={prop.icon} />
                      </Button>
                    )
                  })}
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Correo electrónico"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={validando && !validateEmail(email)}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    value: email,
                    onChange: (e) => setEmail(e.target.value)
                  }}
                />
                <CustomInput
                  labelText="Contraseña"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={validando && !validatePassword(password)}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off',
                    value: password,
                    onChange: (e) => setPassword(e.target.value),
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault()
                        console.log(buttonRef.current)
                        buttonRef.current.click()
                      }
                    }
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="primary"
                  round
                  size="lg"
                  block
                  disabled={signInLoading || !isValid()}
                  onClick={loginClicked}
                  ref={buttonRef}
                >
                  Iniciar sesión
                </Button>
              </CardFooter>
              <span style={{ textAlign: 'center', margin: 25 }}>
                He leído y estoy de acuerdo con los{' '}
                <a href="#pablo">términos y condiciones</a>{' '}
                al usar ablyzapp.
              </span>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}
