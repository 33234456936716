import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Icon from '@material-ui/core/Icon'

// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline'
import Code from '@material-ui/icons/Code'
import Group from '@material-ui/icons/Group'
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from '@material-ui/icons/Check'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import InfoArea from 'components/InfoArea/InfoArea.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle'
import { useAuth } from 'hooks/useAuth'
import { validateEmail } from 'utils'

const useStyles = makeStyles(styles)

export default function RegisterPage ({ history }) {
  const classes = useStyles()
  const { signUp, signUpLoading } = useAuth()
  const [validando, setValidando] = React.useState(false)
  const [aceptoTerminos, setAceptoTerminos] = React.useState(false)
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const registerClicked = async (event) => {
    event.preventDefault()
    setValidando(true)
    await signUp({ firstName, lastName, email, password })
    history.push('iniciar-sesion')
  }

  const isValid = () => {
    if (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      password === '' ||
      !aceptoTerminos ||
      !validateEmail(email)
    ) { return false }
    return true
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Únete a Ablyz!</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={4}>
                  <InfoArea
                    title="Marketing"
                    description="Se parte de esta inovadora manera de ofrecer un bien a la sociedad."
                    icon={Timeline}
                    iconColor="primary"
                  />
                  <InfoArea
                    title="Plataforma segura y confiable"
                    description="Únete a la plataforma más segura y transparente de contrato de servicios."
                    icon={Code}
                    iconColor="primary"
                  />
                  <InfoArea
                    title="Comenta con otros socios"
                    description="Se parte de la red comercial más inovadora, comparte estrategías y contacto con más personas."
                    icon={Group}
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={8} md={7}>
                  <form className={classes.form}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'Nombre(s)',
                        value: firstName,
                        onChange: (e) => setFirstName(e.target.value)
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'Apellido(s)',
                        value: lastName,
                        onChange: (e) => setLastName(e.target.value)
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      error={validando && !validateEmail(email)}
                      inputProps={{
                        type: 'email',
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'Correo electrónico',
                        value: email,
                        onChange: (e) => setEmail(e.target.value)
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        type: 'password',
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        placeholder: 'Contraseña',
                        value: password,
                        onChange: (e) => setPassword(e.target.value)
                      }}
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checked={aceptoTerminos}
                          onClick={() => setAceptoTerminos((v) => !v)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label={
                        <span>
                          He leído y estoy de acuerdo con los{' '}
                          <a href="#pablo">términos y condiciones</a>.
                        </span>
                      }
                    />
                    <div className={classes.center}>
                      <Button
                        round
                        color="primary"
                        disabled={signUpLoading || !isValid()}
                        onClick={registerClicked}
                      >
                        Registrar!
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
