import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import SaveIcon from '@material-ui/icons/Save'
import { v4 as uuidv4 } from 'uuid'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'

import moment from 'moment'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'

import { useQuery } from 'hooks/useQuery'
import { emptyRequest, getReq } from 'utils'
import { useModel } from 'hooks/useModel'

import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardText from 'components/Card/CardText.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardBody from 'components/Card/CardBody.js'
import ImageUpload from 'components/CustomUpload/ImageUpload.js'
import HttpService from 'utils/httpService'
import { useModels } from 'hooks/useModels'
import { storage } from '../../firebase'
import CustomDropdown from 'components/CustomDropdown/CustomDropdown'
import Build from '@material-ui/icons/Build'
import { events as calendarEvents } from 'variables/general.js'
import SweetAlert from 'react-bootstrap-sweetalert'
import AsyncDropdown from 'components/CustomDropdown/AsyncDropdown'
import Map from '@material-ui/icons/Map'
import CustomCalendar from 'components/Calendar/CustomCalendar'

const localizer = momentLocalizer(moment)

const useStyles = makeStyles(styles)

const emptyReq = emptyRequest()
const getPrimaryCategoriesRequest = getReq('categories', {
  primary: true
})
const extraParams = { primary: true }
const PromotionDetail = ({ history }) => {
  const classes = useStyles()
  const query = useQuery()
  const [events, setEvents] = React.useState(calendarEvents)
  const [alert, setAlert] = React.useState(null)
  const { model: category, modelLoading: categoryLoading } = useModel({
    name: 'categories',
    id: query.get('id') || null
  })
  const [primaryCategories, primaryCategoriesLoading] = useModels({
    name: 'categories',
    extraParams
  })
  const [selectedCategory, setSelectedCategory] = React.useState('')
  // Nombre categoria
  const [name, setName] = React.useState('')
  const [nameValidationStatus, setNameValidationStatus] = React.useState('')
  // Descripcion categoria
  const [description, setDescription] = React.useState('')
  const [
    descriptionValidationStatus,
    setDescriptionValidationStatus
  ] = React.useState('')
  // Imágen primaria
  const [primaryImgUrl, setPrimaryImgUrl] = React.useState('')
  const [
    primaryImgUrlValidationStatus,
    setPrimaryImgUrlValidationStatus
  ] = React.useState('')

  const [isPrimary, setIsPrimary] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'))
  const [age, setAge] = React.useState('')

  const handleChangeAge = (event) => {
    setAge(event.target.value)
  }
  const handleChange = (newValue) => {
    setValue(newValue)
  }
  React.useEffect(() => {
    if (category && !categoryLoading) {
      setSelectedCategory(category.parentId)
      setIsPrimary(!category.parentId)
      setName(category.name)
      setDescription(category.description)
      setPrimaryImgUrl(category.photo)
    }
  }, [category, categoryLoading])

  const saveClicked = async () => {
    try {
      setLoading(true)
      if (name === '') {
        setNameValidationStatus('error')
      }
      if (description === '') {
        setDescriptionValidationStatus('error')
      }
      if (!primaryImgUrl) {
        setPrimaryImgUrlValidationStatus('error')
      }
      if (name === '' || description === '' || !primaryImgUrl) return
      const code = uuidv4()
      const storageRef = storage.ref().child('categories')
      const filename = primaryImgUrl?.name
      const ext = filename.split('.')[1]
      const fileRef = storageRef.child(code + `.${ext}`)
      await fileRef.put(primaryImgUrl)
      const fileUrl = await fileRef.getDownloadURL()

      const body = {
        name,
        description,
        photo: fileUrl,
        parentId: isPrimary ? null : selectedCategory
      }
      const resp = await HttpService.post('categories', body)
      if (resp?.id) {
        history.push('/admin/categorias')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const selectedEvent = (event) => {
    window.alert(event.title)
  }
  const addNewEventAlert = (slotInfo) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: 'block', marginTop: '-100px' }}
        title="Input something"
        onConfirm={(e) => addNewEvent(e, slotInfo)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
      />
    )
  }
  const eventColors = (event) => {
    let backgroundColor = 'event-'
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + 'default')
    return {
      className: backgroundColor
    }
  }
  const addNewEvent = (e, slotInfo) => {
    const newEvents = events
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    })
    setAlert(null)
    setEvents(newEvents)
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const handleSimple = (event) => {
    setSelectedCategory(event.target.value)
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={8} sm={8} md={8}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h3 className={classes.cardTitle}>
                {query.get('id')
                  ? (
                  <>
                    <strong>Editando</strong> Banner Promocional
                  </>
                    )
                  : (
                  <>
                    <strong>Nuevo</strong> Banner Promocional
                  </>
                    )}
              </h3>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Información general de Promoción
                  </Typography>
                </GridItem>
                <GridItem xs={6}>
                  <AsyncDropdown
                    label="Seleccionar Subscriptor"
                    url="users?role=regular"
                    optionLabelSelector={(option) =>
                      `${option.displayName} - ${option.email}`
                    }
                  />
                  {/* <CustomDropdown
                    buttonIcon={Build}
                    buttonProps={{
                      round: true,
                      style: { marginBottom: "0", width: "100%" },
                      color: "info",
                    }}
                    buttonText={
                      <span>
                        Es necesario seleccionar un <strong>subscriptor</strong>
                      </span>
                    }
                    dropdownList={["Cargando subscriptores..."]}
                  /> */}
                </GridItem>
                <GridItem xs={6}>
                  <AsyncDropdown
                    label="Seleccionar Membresía"
                    url="categories"
                    optionLabelSelector={(option) => option.name}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <TextField
                    variant="outlined"
                    label="Título de Promoción*"
                    size="small"
                    fullWidth
                    style={{ marginTop: 16 }}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <TextField
                    variant="outlined"
                    label="Descripción (opcional)"
                    size="small"
                    fullWidth
                    style={{ marginTop: 16 }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer justify="space-around" style={{ marginTop: 15 }}>
                <GridItem xs={8}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Typography
                        style={{ fontWeight: 'bold', marginBottom: 20 }}
                      >
                        Rango de Fechas
                      </Typography>
                    </GridItem>
                    <GridItem xs={6}>
                      <TextField
                        id="date"
                        label="Fecha de Inicio"
                        type="date"
                        defaultValue="2017-05-24"
                        style={{ width: '100%', marginBottom: 20 }}
                        variant="outlined"
                        size="small"
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <TextField
                        id="date"
                        label="Fecha Final"
                        type="date"
                        defaultValue="2017-05-24"
                        style={{ width: '100%', marginBottom: 20 }}
                        variant="outlined"
                        size="small"
                      />
                    </GridItem>
                  </GridContainer>
                  <Typography style={{ fontWeight: 'bold', marginBottom: 20 }}>
                    Calendario de disponibilidad
                  </Typography>
                  <CustomCalendar
                    events={[]}
                    onSelectEvent={(e) => console.log(e)}
                  />
                  {/* <BigCalendar
                    selectable
                    localizer={localizer}
                    events={[]}
                    components={{toolbar: }}
                    views={"month"}
                    defaultView="month"
                    style={{ height: 400 }}
                    scrollToTime={new Date()}
                    defaultDate={new Date()}
                    onSelectEvent={(event) => selectedEvent(event)}
                    onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                  /> */}
                </GridItem>
                <GridItem xs={4} alignItems="center">
                  <Typography style={{ fontWeight: 'bold', marginBottom: 18 }}>
                    Información Promocional
                  </Typography>
                  <FormControl fullWidth size="small">
                    <InputLabel id="promotion-priority-1" variant="outlined">
                      Nivel de prioridad en Categoría
                    </InputLabel>
                    <Select
                      labelId="promotion-priority-1"
                      id="promotion-priority-select"
                      value={age}
                      label="Nivel de prioridad en Categoría"
                      onChange={handleChangeAge}
                      variant="outlined"
                    >
                      <MenuItem value={1}>Básico</MenuItem>
                      <MenuItem value={2}>Moderado</MenuItem>
                      <MenuItem value={3}>Alto</MenuItem>
                      <MenuItem value={4}>Máximo</MenuItem>
                    </Select>
                  </FormControl>
                  <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                  <Typography style={{ fontWeight: 'bold', marginBottom: 18 }}>
                    Imágen Principal
                  </Typography>
                  <ImageUpload
                    loadedUrl={primaryImgUrl}
                    addButtonProps={{
                      color: 'rose',
                      round: true
                    }}
                    changeButtonProps={{
                      color: 'rose',
                      round: true
                    }}
                    removeButtonProps={{
                      color: 'danger',
                      round: true
                    }}
                    onFileChanged={(file) => setPrimaryImgUrl(file)}
                  />
                  {primaryImgUrlValidationStatus === 'error' && (
                    <Typography color="error">* Imágen requerida</Typography>
                  )}
                  <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                  <Typography style={{ fontWeight: 'bold', marginBottom: 18 }}>
                    Ubicación de Audiencia
                  </Typography>
                  <Typography style={{ marginBottom: 18 }}>
                    Calzada de los Ángeles #104-B 83000 Hermosillo, Sonora,
                    México
                  </Typography>
                  <Button color="info" endIcon={<Map />}>
                    Seleccionar ubicación
                  </Button>
                </GridItem>

                {/*
                <GridItem xs={12} sm={4} md={4}>
                  <legend>
                    <strong>Imágen secundaria</strong>
                  </legend>
                  <ImageUpload
                    defaultPath={secondaryImgUrl}
                    addButtonProps={{
                      color: "rose",
                      round: true,
                    }}
                    changeButtonProps={{
                      color: "rose",
                      round: true,
                    }}
                    removeButtonProps={{
                      color: "danger",
                      round: true,
                    }}
                    onFileChanged={(file) => setSecondaryImgUrl(file)}
                  />
                </GridItem> */}
              </GridContainer>

              <Button
                color="success"
                onClick={saveClicked}
                className={classes.registerButton}
                disabled={loading || name === '' || description === ''}
                endIcon={<SaveIcon />}
              >
                {loading ? 'Cargando...' : 'Guardar Banner Promocional'}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default PromotionDetail
