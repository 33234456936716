import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Hidden from '@material-ui/core/Hidden'
import Popper from '@material-ui/core/Popper'
import Divider from '@material-ui/core/Divider'

// @material-ui/icons
import Person from '@material-ui/icons/Person'
import Notifications from '@material-ui/icons/Notifications'

// core components
import Button from 'components/CustomButtons/Button.js'

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import { useAuth } from 'hooks/useAuth'
import { useApp } from 'hooks/useApp'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

export default function HeaderLinks (props) {
  const [openNotification, setOpenNotification] = React.useState(null)
  const { signOut, user } = useAuth()
  const { role } = useApp()
  const history = useHistory()
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null)
    } else {
      setOpenNotification(event.currentTarget)
    }
  }
  const handleCloseNotification = () => {
    setOpenNotification(null)
  }
  const [openProfile, setOpenProfile] = React.useState(null)
  const handleClickProfile = (event) => {
    if (
      openProfile
      // && openProfile.contains(event.target)
    ) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }
  const handleCloseProfile = () => {
    setOpenProfile(null)
  }
  const classes = useStyles()
  const { rtlActive } = props
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover)
  const managerClasses = classNames({
    [classes.managerClasses]: true
  })

  async function onSignOutPressed () {
    try {
      handleCloseProfile()
      await signOut()
      history.push('/publico/iniciar-sesion')
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <div>
      {/* <CustomInput
        rtlActive={rtlActive}
        formControlProps={{
          className: classes.top + " " + classes.search,
        }}
        inputProps={{
          placeholder: "Search",
          inputProps: {
            "aria-label": "Search",
            className: classes.searchInput,
          },
        }}
      /> */}
      {/* <Button
        color="white"
        aria-label="edit"
        justIcon
        round
        className={searchButton}
      >
        <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
      </Button> */}
      {/* <Button
        color="transparent"
        simple
        aria-label="Dashboard"
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : "",
        }}
      >
        <Dashboard
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
          }
        />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>{"Dashboard"}</span>
        </Hidden>
      </Button> */}
      {role !== 'regular' && (
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? 'notification-menu-list' : null}
            aria-haspopup="true"
            onClick={handleClickNotification}
            className={classes.buttonLink}
            muiClasses={{
              label: ''
            }}
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                ' ' +
                (rtlActive
                  ? classes.links + ' ' + classes.linksRTL
                  : classes.links)
              }
            />
            {/* <span className={classes.notifications}>5</span> */}
            <Hidden mdUp implementation="css">
              <span
                onClick={handleClickNotification}
                className={classes.linkText}
              >
                {'5  Notificaciones'}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={Boolean(openNotification)}
            anchorEl={openNotification}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openNotification,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="notification-menu-list"
                style={{ transformOrigin: '0 0 0' }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleCloseNotification}>
                    <MenuList role="menu">
                      <>
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={dropdownItem}
                        >
                          {'Persona: Ha solicitado un servicio'}
                        </MenuItem>
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={dropdownItem}
                        >
                          {'Persona: Quiere agendar una cita'}
                        </MenuItem>
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={dropdownItem}
                        >
                          {'Persona: Quiere agendar una cita'}
                        </MenuItem>
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={dropdownItem}
                        >
                          {'Persona: te ha comenzado a seguir'}
                        </MenuItem>
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={dropdownItem}
                        >
                          {'Persona: te ha comenzado a seguir'}
                        </MenuItem>
                      </>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: ''
          }}
        >
          <Person className={classes.headerLinksSvg + ' ' + classes.links} />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {'Cuenta'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {role !== 'regular'
                      ? (
                      <>
                        <MenuItem
                          onClick={handleCloseProfile}
                          className={dropdownItem}
                        >
                          {'Ver mi perfïl'}
                        </MenuItem>
                        <MenuItem
                          onClick={handleCloseProfile}
                          className={dropdownItem}
                        >
                          {'Manejo de subscripción'}
                        </MenuItem>
                        <Divider light />
                        <MenuItem
                          onClick={onSignOutPressed}
                          className={dropdownItem}
                        >
                          {'Cerrar Sesión'}
                        </MenuItem>
                      </>
                        )
                      : (
                      <MenuItem
                        onClick={onSignOutPressed}
                        className={dropdownItem}
                      >
                        {'Cerrar Sesión'}
                      </MenuItem>
                        )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
}
