import React from 'react'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import AdminLayout from 'layouts/Admin.js'
import { useAuth } from 'hooks/useAuth'
import { CircularProgress, Grid, Typography } from '@material-ui/core'

import loadingLogo from 'assets/img/app_logo.png'

const hist = createBrowserHistory()

const PrivateRouter = () => {
  const { user, userLoading } = useAuth()
  const [loadingAnimation, setLoadingAnimation] = React.useState(true)

  React.useEffect(() => {
    let mounted = true
    if (!user || userLoading) {
      if (mounted) {
        setTimeout(() => setLoadingAnimation(() => false), 1000)
      }
    }
    return () => (mounted = false)
  }, [user, userLoading])

  if (loadingAnimation) {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignContent="center"
        style={{ height: '100vh' }}
      >
        <img
          src={loadingLogo}
          height={100}
          style={{ marginBottom: 20 }}
          alt="Logo ablyz cargando..."
        />
        <Typography
          style={{ color: 'darkgray', textAlign: 'center', marginBottom: 20 }}
        >
          Cargando...
        </Typography>
        <center>
          <CircularProgress style={{ color: '#1dbfa4' }} />
        </center>
      </Grid>
    )
  }

  return (
    <Router history={hist}>
      <Switch>
        <Route path="/admin" component={AdminLayout} />
        <Redirect exact from="*" to="/admin/dashboard" />
      </Switch>
    </Router>
  )
}

export default PrivateRouter
