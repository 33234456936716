import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import Refresh from '@material-ui/icons/Refresh'
import Edit from '@material-ui/icons/Edit'
import Place from '@material-ui/icons/Place'
import ArtTrack from '@material-ui/icons/ArtTrack'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'

import MyCalendarImage from 'assets/img/brand/mi-calendario.jpeg'
import MyMapImage from 'assets/img/brand/mi-map.jpeg'
import MyProfileImage from 'assets/img/brand/mi-perfil.jpeg'
import MyReferralsImage from 'assets/img/brand/mis-referencias.jpeg'
import MyServicesImage from 'assets/img/brand/mis-servicios.jpeg'
import MySubscriptionsImage from 'assets/img/brand/mis-subscripciones.jpg'
import { useApp } from 'hooks/useApp'

const useStyles = makeStyles(styles)

export default function Dashboard ({ history }) {
  const [cards, setCards] = React.useState([])
  const { role } = useApp()

  React.useEffect(() => {
    console.log('dashboard useffect:', role)

    if (!role) return
    const newCards = []
    newCards.push({
      title: 'Administrar mi perfíl',
      description:
        'Maneja toda tu información de perfíl en un sólo lugar y mantén tu información siempre actualizada.',
      image: MyProfileImage,
      disabled: false,
      navigateTo: '/admin/perfil'
    })
    newCards.push({
      title: 'Administrar mis subscripciones',
      description:
        'Controla y revisa la vigencia de tus suscripciones, con la habilidad de comprar suscripciones que tienes disponibles.',
      image: MySubscriptionsImage,
      disabled: false,
      navigateTo:
        role === 'regular'
          ? '/admin/nueva-subscripcion'
          : '/admin/mis-subscripciones'
    })
    newCards.push({
      title: 'Reporte de invitaciones',
      description:
        'Puedes ver tu crecimiento en comisiones a traves de tus personas invitadas por referencia.',
      image: MyReferralsImage,
      disabled: role !== 'admin' && role !== 'subscriber',
      navigateTo: '/admin/mis-referencias'
    })
    newCards.push({
      title: 'Administrar mis servicios',
      description:
        'Administra y controla la disponibilidad e información de los servicios brindados a través de tus diferentes suscripciones.',
      image: MyServicesImage,
      disabled: role !== 'admin' && role !== 'subscriber',
      navigateTo: '/admin/mis-servicios'
    })
    newCards.push({
      title: 'Ver servicios pendientes',
      description:
        'Revisa la ágenda y corrobora eventos que tú tienes pendientes por hacer.',
      image: MyCalendarImage,
      disabled: role !== 'admin' && role !== 'subscriber',
      navigateTo: '/admin/calendario'
    })
    newCards.push({
      title: 'Ver servicios activos',
      description:
        'Revisa de manera interactiva todos los tipos de servicios que ofrecen los otros proveedores.',
      image: MyMapImage,
      disabled: role !== 'admin' && role !== 'subscriber',
      navigateTo: '/admin/mapa'
    })
    setCards(newCards)
  }, [role])

  const classes = useStyles()
  return (
    <div>
      <h1 style={{ marginTop: -20, marginBottom: 50 }}>
        Bienvenido a <strong>Ablyz App!</strong>
      </h1>
      <br />
      <GridContainer>
        {cards.map((card, index) => (
          <GridItem key={index} xs={12} sm={12} md={4}>
            <Card product className={classes.cardHover}>
              <CardHeader image>
                <a href="#mi-perfil" onClick={(e) => e.preventDefault()}>
                  <img
                    src={card.image}
                    alt="..."
                    style={
                      card.disabled
                        ? {
                          '-webkit-filter': 'grayscale(100%)',
                          filter: 'grayscale(100%)'
                        }
                        : {}
                    }
                  />
                </a>
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardProductTitle}>
                  <a href="#mi-perfil" onClick={(e) => e.preventDefault()}>
                    <strong>{card.title}</strong>
                  </a>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  {card.description}
                </p>
              </CardBody>
              <CardFooter product justify="flex-end">
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Button
                      disabled={card.disabled}
                      color={card.disabled ? 'gray' : 'success'}
                      onClick={() => history.push(card.navigateTo)}
                    >
                      {card.disabled ? 'NO CUENTAS CON SUBSCRIPCIÓN' : 'ENTRAR'}
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  )
}
