import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'

// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Clearfix from 'components/Clearfix/Clearfix.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardAvatar from 'components/Card/CardAvatar.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'

import avatar from 'assets/img/faces/marc.jpg'
// import { useProfile } from "hooks/useProfile";
import { useAuth } from 'hooks/useAuth'
import HttpService from 'utils/httpService'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function UserProfile () {
  const classes = useStyles()
  // const { profile, profileLoading } = useProfile();
  const { user, userLoading, session, sessionLoading } = useAuth()

  // nombres
  const [firstName, setFirstName] = React.useState('')
  const [
    firstNameValidationStatus,
    setFirstNameValidationStatus
  ] = React.useState(user?.firstName || '')
  // apellidos
  const [lastName, setLastName] = React.useState('')
  const [
    lastNameValidationStatus,
    setLastNameValidationStatus
  ] = React.useState('')
  // telefono
  const [phone, setPhone] = React.useState('')
  const [phoneValidationStatus, setPhoneValidationStatus] = React.useState('')
  // telefono
  const [occupation, setOccupation] = React.useState('')
  const [
    occupationValidationStatus,
    setOccupationValidationStatus
  ] = React.useState('')
  // rfc
  const [rfc, setRfc] = React.useState('')
  const [rfcValidationStatus, setRfcValidationStatus] = React.useState('')
  // telefono
  const [description, setDescription] = React.useState('')
  const [
    descriptionValidationStatus,
    setDescriptionValidationStatus
  ] = React.useState('')
  const [updateLoading, setUpdateLoading] = React.useState(false)

  React.useEffect(() => {
    if (user && !userLoading && session && !sessionLoading) {
      setFirstName(user.firstName || '')
      setLastName(user.lastName || '')
      setPhone(session.phoneNumber || '')
      setOccupation(user?.billing?.occupation || '')
      setRfc(user?.billing?.rfc || '')
      setDescription(user?.billing?.description || '')
      setPhone(user?.billing?.phoneNumber || '')
    }
  }, [user, userLoading])

  const isValid = React.useMemo(
    () =>
      !(firstNameValidationStatus === 'error' ||
      lastNameValidationStatus === 'error' ||
      phoneValidationStatus === 'error' ||
      occupationValidationStatus === 'error' ||
      descriptionValidationStatus === 'error'),
    [
      firstNameValidationStatus,
      lastNameValidationStatus,
      phoneValidationStatus,
      occupationValidationStatus,
      descriptionValidationStatus
    ]
  )

  if (!user || userLoading) return null
  if (!session || sessionLoading) return null

  const updateProfileClicked = async () => {
    const obj = {
      id: user?.id,
      firstName,
      lastName,
      phone,
      billing: {
        occupation,
        rfc,
        description,
        phoneNumber: phone
      }
    }
    if (phone !== session.phoneNumber) {
      // escribir verificacion de numero por firebase - EJEMPLO:
      // FirebaseAuth.instance.verifyPhoneNumber(
      //   phoneNumber: phoneNumber,
      //   timeout: const Duration(minutes: 2),
      //   verificationCompleted: (credential) async {
      //     await (await FirebaseAuth.instance.currentUser()).updatePhoneNumberCredential(credential);
      //     // either this occurs or the user needs to manually enter the SMS code
      //   },
      //   verificationFailed: null,
      //   codeSent: (verificationId, [forceResendingToken]) async {
      //     String smsCode;
      //     // get the SMS code from the user somehow (probably using a text field)
      //     final AuthCredential credential =
      //       PhoneAuthProvider.getCredential(verificationId: verificationId, smsCode: smsCode);
      //     await (await FirebaseAuth.instance.currentUser()).updatePhoneNumberCredential(credential);
      //   },
      //   codeAutoRetrievalTimeout: null);
    }
    try {
      setUpdateLoading(true)
      const res = await HttpService.put('users', obj)
      console.log(res)
      // if (res) {
      //   window.location.reload();
      // }
    } catch (error) {
      console.error(error)
    } finally {
      setUpdateLoading(false)
    }
  }

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                Editar información de perfíl
                <br />
                <br />
                <small>
                  <strong>Información de contacto privada</strong>
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Correo electrónico"
                    id="correo-electronico"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: user.email
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Nombre(s)"
                    id="first-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: firstName,
                      onChange: (event) => {
                        if (event.target.value !== '') {
                          setFirstNameValidationStatus('success')
                        } else {
                          setFirstNameValidationStatus('error')
                        }
                        setFirstName(event.target.value)
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Apellido(s)"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: lastName,
                      onChange: (event) => {
                        if (event.target.value !== '') {
                          setLastNameValidationStatus('success')
                        } else {
                          setLastNameValidationStatus('error')
                        }
                        setLastName(event.target.value)
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Número de celular"
                    id="phone"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: phone,
                      onChange: (event) => {
                        if (event.target.value !== '') {
                          setPhoneValidationStatus('success')
                        } else {
                          setPhoneValidationStatus('error')
                        }
                        setPhone(event.target.value)
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Ocupación ejercida"
                    id="occupation"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: occupation,
                      onChange: (event) => {
                        if (event.target.value !== '') {
                          setOccupationValidationStatus('success')
                        } else {
                          setOccupationValidationStatus('error')
                        }
                        setOccupation(event.target.value)
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="RFC"
                    id="rfc"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: rfc,
                      onChange: (event) => {
                        if (event.target.value !== '') {
                          setRfcValidationStatus('success')
                        } else {
                          setRfcValidationStatus('error')
                        }
                        setRfc(event.target.value)
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Divider />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h3
                    className={classes.cardIconTitle}
                    style={{ marginTop: 30 }}
                  >
                    <small>
                      <strong>Información de contacto pública</strong>
                    </small>
                  </h3>
                  <InputLabel style={{ color: '#AAAAAA' }}>
                    Acerca de mi
                  </InputLabel>
                  <CustomInput
                    labelText="Escríbe una breve descripción del perfil, se puede incluír información de contacto ó disponibilidad"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: description.split('\n').length,
                      value: description,
                      onChange: (event) => {
                        if (event.target.value !== '') {
                          setDescriptionValidationStatus('success')
                        } else {
                          setDescriptionValidationStatus('error')
                        }
                        setDescription(event.target.value)
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                color="success"
                disabled={!isValid || updateLoading}
                className={classes.updateProfileButton}
                onClick={updateProfileClicked}
              >
                {updateLoading ? 'Cargando' : 'Actualizar perfíl'}
              </Button>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>Alec Thompson</h4>
              <p className={classes.description}>
                Don{"'"}t be scared of the truth because we need to restart the
                human foundation in truth And I love you like Kanye loves Kanye
                I love Rick Owens’ bed design but the back is...
              </p>
              <Button color="rose" round>
                Follow
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  )
}
