import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'

// core components
import Button from 'components/CustomButtons/Button.js'

import defaultImage from 'assets/img/image_placeholder.jpg'
import defaultAvatar from 'assets/img/placeholder.jpg'
import Photo from '@material-ui/icons/Photo'

export default function ImageUpload (props) {
  const { onFileChanged, loadedUrl } = props

  const [file, setFile] = React.useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  )
  const fileInput = React.createRef()

  const handleImageChange = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => {
      setFile({ file, base64: reader.result })
      setImagePreviewUrl(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  React.useEffect(() => {
    if (onFileChanged && file) {
      onFileChanged(file)
    }
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  }, [file, onFileChanged])

  const handleClick = () => {
    fileInput.current.click()
  }
  const handleRemove = () => {
    setFile(null)
    onFileChanged(null)
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage)
    fileInput.current.value = null
  }
  const { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props

  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={'thumbnail' + (avatar ? ' img-circle' : '')}>
        {loadedUrl
          ? (
          <>
            <img src={loadedUrl} alt="..." />
            <span>Imágen actual</span>
          </>
            )
          : (
          <img src={imagePreviewUrl} alt="..." />
            )}
      </div>
      <div>
        {file === null
          ? (
          <Button
            {...addButtonProps}
            onClick={() => handleClick()}
            color="info"
            endIcon={<Photo />}
          >
            {avatar ? 'Agregar foto' : 'Seleccionar imágen'}
          </Button>
            )
          : (
          <span>
            <Button {...changeButtonProps} onClick={() => handleClick()}>
              Cambiar
            </Button>
            {avatar ? <br /> : null}
            <Button {...removeButtonProps} onClick={() => handleRemove()}>
              <i className="fas fa-times" /> Eliminar
            </Button>
          </span>
            )}
      </div>
    </div>
  )
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  defaultPath: PropTypes.string,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  onFileChanged: PropTypes.func
}
