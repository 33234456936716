import * as React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, CircularProgress } from '@material-ui/core'
import HttpService from 'utils/httpService'

function sleep (delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

export default function AsyncDropdown ({
  url,
  optionLabelSelector,
  label = 'Sin etiqueta'
}) {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0

  React.useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    (async () => {
      const res = await HttpService.get(url)
      if (active && Boolean(url)) {
        setOptions([...res])
      }
    })()

    return () => {
      active = false
    }
  }, [loading, url])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open, url])

  return (
    <Autocomplete
      id="async-dropdown"
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      style={{ marginTop: 18 }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={optionLabelSelector}
      options={options}
      loading={loading}
      loadingText="Cargando..."
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading
                  ? (
                  <CircularProgress color="inherit" size={20} />
                    )
                  : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  )
}
