import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Assignment from '@material-ui/icons/Assignment'
import EditOutlined from '@material-ui/icons/EditOutlined'
import Info from '@material-ui/icons/Info'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import ReactTable from 'components/ReactTable/ReactTable.js'
import AddIcon from '@material-ui/icons/Add'

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { useHttp } from 'hooks/useHttp'
import { useApp } from 'hooks/useApp'
import { getReq, emptyRequest } from 'utils'

import XLSX from 'xlsx'
import { Tooltip } from '@material-ui/core'
import { USER_ROLES } from '../../constants'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
}

const useStyles = makeStyles(styles)
const emptyReq = emptyRequest()

export default function Categories ({ history }) {
  const { role } = useApp()
  const [data, setData] = React.useState([])
  const [excelLoading, setExcelLoading] = React.useState(false)
  const [modelsRequest, setModelsRequest] = React.useState(emptyReq)
  const [models, modelsLoading, modelsError] = useHttp(modelsRequest)
  const classes = useStyles()

  const dataToRow = React.useCallback(
    (prop, key) => {
      return {
        id: key,
        name: prop.name,
        description: prop.description,
        photo: (
          <GridContainer justify="center">
            <GridItem>
              {prop.photo
                ? (
                  <img
                    style={{ height: 60 }}
                    src={prop.photo}
                    alt={prop.name.toLowerCase()}
                  />
                )
                : (
                  <i>Sin imágen</i>
                )}
            </GridItem>
          </GridContainer>
        ),
        detail: (
          <>
            <strong>{prop.subCategories.length}</strong> Subcategorías
            <br />
            <strong>{prop.services.length}</strong> Servicios
          </>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            {/* <Tooltip title="Ver información">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  const obj = data.find((o) => o.id === key)
                }}
                color="info"
                className="info"
              >
                <Info />
              </Button>
            </Tooltip> */}
            {/* use this button to add a edit kind of action */}
            <Tooltip title="Editar">
              <>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => history.push('/admin/categoria?id=' + key)}
                  color="warning"
                  className="edit"
                >
                  <EditOutlined />
                </Button>
                <strong>Editar</strong>
              </>
            </Tooltip>
            {/* <Tooltip title="Eliminar categoría">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  const obj = data.find((o) => o.id === key)
                }}
                color="danger"
                className="delete"
              >
                <DeleteOutlined />
              </Button>
            </Tooltip> */}
            {/* use this button to remove the data row */}
            {/* <Button
            justIcon
            round
            simple
            onClick={() => {
              var newData = data;
              newData.find((o, i) => {
                if (o.id === key) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  newData.splice(i, 1);
                  return true;
                }
                return false;
              });
              setData([...newData]);
            }}
            color="danger"
            className="remove"
          >
            <Close />
          </Button>{" "} */}
          </div>
        )
      }
    },
    [data, models]
  )

  React.useEffect(() => {
    const loadModelsReq = getReq('categories', { size: 500 })
    setModelsRequest(loadModelsReq)
  }, [])

  React.useEffect(() => {
    if (!modelsLoading && !modelsError && models) {
      if (!models.length) return
      const mapped = models.map((m) => dataToRow(m, m.id))
      setData(mapped)
    }
  }, [models, modelsLoading, modelsError])

  function generateExcel (entries) {
    setExcelLoading(true)
    const filename = 'Listado_Categorias_Ablyz.xlsx'
    const ws_name = 'Visitas'
    const wb = {
      SheetNames: [],
      Sheets: {}
    }
    const ws = {
      A2: { v: 'Id', t: 's' },
      B2: { v: 'Nombre', t: 's' },
      C2: { v: 'Descripcion', t: 's' },
      D2: { v: 'Fecha edición', t: 's' }
    }
    let renInicial = 3
    for (const i in entries) {
      const { id, name, description, editedOn } = entries[i]
      ws['A' + renInicial] = {
        v: id,
        t: 's'
      }
      ws['B' + renInicial] = { v: name, t: 's' }
      ws['C' + renInicial] = { v: description, t: 's' }
      ws['D' + renInicial] = { v: editedOn.toString(), t: 's' }
      renInicial++
    }
    const wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }]
    ws['!cols'] = wscols
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial }
    })
    wb.SheetNames.push(ws_name)
    wb.Sheets[ws_name] = ws
    XLSX.writeFile(wb, filename, { cellStyles: true })
    setExcelLoading(false)
  }

  const downloadReportClicked = async () => {
    const flatten = (data) => {
      return data.reduce((r, { referrals, ...rest }) => {
        r.push(rest)
        if (referrals) r.push(...flatten(referrals))
        return r
      }, [])
    }

    const flattened = flatten(models)
    // console.log(flattened);
    generateExcel(flattened)
  }

  if (modelsLoading) return null

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify="flex-end">
          <Button
            round
            color="success"
            disabled={role !== USER_ROLES.Admin || excelLoading}
            onClick={() => history.push('/admin/categoria')}
          >
            Agregar categoría
            <AddIcon style={{ marginLeft: 10 }} />
          </Button>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h3 className={classes.cardIconTitle}>
              Administración y control de categorías
            </h3>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: 'Nombre',
                  accessor: 'name'
                },
                {
                  Header: 'Descripción',
                  accessor: 'description',
                  disableFilters: true
                },
                {
                  Header: 'Fotografía principal',
                  accessor: 'photo',
                  disableFilters: true
                },
                {
                  Header: 'Detalle de relación',
                  accessor: 'detail',
                  disableFilters: true
                },
                {
                  Header: 'Acciones',
                  accessor: 'actions'
                }
              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
