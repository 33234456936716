import React from 'react'
import { auth } from '../firebase'
import firebase from 'firebase/app'

import { useAuthState } from 'react-firebase-hooks/auth'
import { emptyRequest, getRequest } from '../constants/request'
import { useHttp } from './useHttp'
import { useAlert } from './useAlert'
import { useApp } from './useApp'
import HttpService from 'utils/httpService'

const AuthContext = React.createContext()

const empty = emptyRequest()

export function AuthProvider (props) {
  const { token, setToken, setRole } = useApp()
  const { showAlert } = useAlert()

  const [signUpLoading, setSignUpLoading] = React.useState(false)
  const [signInLoading, setSignInLoading] = React.useState(false)

  // firebase
  const [session, sessionLoading, sessionError] = useAuthState(auth)

  // hook auto-refresh
  const [userRequest, setUserRequest] = React.useState(empty)
  const [userResponse, userResponseLoading, userError] = useHttp(userRequest)

  const signUp = React.useCallback(
    async (body) => {
      try {
        if (signUpLoading) return
        setSignUpLoading(true)
        const resp = await HttpService.post('users', body, false)
        if (resp.accessToken) {
          const res = await auth.signInWithCustomToken(resp.accessToken)
          if (res && res.user && !res.user.emailVerified) {
            await res.user.sendEmailVerification()
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        setSignUpLoading(false)
      }
    },
    [signUpLoading]
  )

  const signIn = React.useCallback(
    async (email, password) => {
      try {
        if (signInLoading) return
        setSignInLoading(true)
        const body = {
          email,
          password
        }
        const res = await HttpService.post('users/auth', body, false)
        if (res.isVerified) {
          await auth.signInWithCustomToken(res.token)
        } else {
          showAlert({
            severity: 'warning',
            message:
              'Es necesaria la verificación de correo antes de continuar.'
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        setSignInLoading(false)
      }
    },
    [signInLoading, showAlert]
  )

  const signOut = React.useCallback(async () => {
    try {
      await auth.signOut()
    } catch (e) {
      console.warn(e)
    }
  }, [])

  const signInWithFacebook = React.useCallback(async () => {
    try {
      console.log('signInWithFacebook')
      const provider = new firebase.auth.FacebookAuthProvider()
      firebase.auth().useDeviceLanguage()
      firebase.auth().languageCode = navigator.language
      provider.setCustomParameters({
        display: 'popup'
      })
      const result = await auth.signInWithPopup(provider)
      const credential = result.credential
      const accessToken = credential.accessToken
      await auth.signInWithCustomToken(accessToken)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const signInWithGoogle = React.useCallback(async () => {
    try {
    } catch (error) {}
  }, [])

  const onTokenChange = React.useCallback(
    (userCredential) => {
      if (userCredential) {
        userCredential.getIdToken().then((newToken) => {
          setToken(newToken)
        })
      }
    },
    [setToken]
  )

  React.useEffect(() => {
    if (!userResponseLoading && userResponse) {
      const { permissions } = userResponse
      setRole(permissions.role)
    }
  }, [userResponse, userResponseLoading])

  React.useEffect(() => {
    let mounted = true
    let unsub = null
    if (mounted && session) {
      unsub = auth.onIdTokenChanged(onTokenChange)
    }
    return () => {
      mounted = false
      if (unsub) unsub()
    }
  }, [session, onTokenChange])

  React.useEffect(() => {
    if (token) {
      const meReq = getRequest(
        'users/me?permissions=true&billingData=true&subscriptions=true'
      )
      meReq.alert = true
      setUserRequest(() => meReq)
    } else {
      setUserRequest(empty)
    }
  }, [token])

  const memData = React.useMemo(() => {
    return {
      session,
      sessionLoading,
      user: userResponse,
      userLoading: userResponseLoading,
      userError: userError || sessionError,
      signUp,
      signUpLoading,
      signIn,
      signInLoading,
      signInWithFacebook,
      signOut
    }
  }, [
    session,
    sessionLoading,
    sessionError,
    userResponse,
    userResponseLoading,
    userError,
    signUp,
    signUpLoading,
    signIn,
    signInLoading,
    signInWithFacebook,
    signInWithGoogle,
    signOut
  ])

  return <AuthContext.Provider value={memData} {...props} />
}

export function useAuth () {
  const context = React.useContext(AuthContext)
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw 'error: auth context not defined.'
  }
  return context
}
