import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

let options = null
if (process.env.NODE_ENV === 'development') {
  options = {
    apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEV_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID
  }
} else if (process.env.NODE_ENV === 'production') {
  options = {
    apiKey: process.env.REACT_APP_PRODUCTION_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PRODUCTION_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PRODUCTION_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_PRODUCTION_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PRODUCTION_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:
      process.env.REACT_APP_PRODUCTION_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_ID
  }
}
const app = firebase.initializeApp(options)

export const auth = app.auth()
export const storage = app.storage()

export default app
