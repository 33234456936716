const emptyRequest = () => ({
  req: null,
  url: null,
  params: null,
  body: null
})

const getReq = (url, params = {}) => ({
  req: 'GET',
  url,
  params,
  body: null
})

const postReq = (url, body, params = {}) => ({
  req: 'POST',
  url,
  params,
  body
})

const updateReq = (url, id, body, params = {}) => ({
  req: 'PUT',
  url,
  id,
  params,
  body
})

const deleteReq = (url, id, body = null) => ({
  req: 'DELETE',
  url,
  id,
  params: null,
  body
})

export { emptyRequest, getReq, postReq, updateReq, deleteReq }
